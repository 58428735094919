import axios from 'axios'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppState, IPlaces } from '../../types'

interface placesState {
    places: IPlaces[];
    isLoading: boolean;
    error: string;
}

const initialState: placesState = {
    places: [],
    isLoading: false,
    error: '',
}

const places = createSlice( {
    name: 'places',
    initialState,
    reducers: {
        setplaces(state, action: PayloadAction<IPlaces[]>) {
            state.places = action.payload;
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setError(state, action: PayloadAction<string>) {
            state.error = action.payload;
        }
    }
})

export const placesReducer = places.reducer;

export const {
    setplaces,
    setIsLoading,
    setError,
} = places.actions;

export function fetchPlaces() {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        try {
            dispatch(setIsLoading(true));
            const { data } = await axios.get(process.env.REACT_APP_WEB_API_URL + 'places')
            dispatch(setplaces(data));
        } catch (error: any) {
            setError(error.message)
        } finally {
            dispatch(setIsLoading(false))
        }
    }
}