const FormatPrice = (price: number | string): string => {
    const priceString = typeof price === 'number' ? price.toString() : price;
    const reversedPriceString = priceString.split('').reverse().join('');
    const groups = reversedPriceString.match(/\d{1,3}/g);

    let formattedPrice = groups ? groups.join('.').split('').reverse().join('') : priceString;

    // Check if there's a decimal followed by a single number at the end and remove them
    formattedPrice = formattedPrice.replace(/\.(\d)$/, '');

    return formattedPrice;
};

export default FormatPrice