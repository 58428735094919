import { PackStructure } from "../../../types";

export function SortPacks(packs: PackStructure[]) {
    const order: string[] = [
        '23/5-pakken',
        'Ingeniørpakke',
        'Medisinpakke - Norge',
        'Medisinpakke - Utland',
        'Påbygg med fagbrev',
        'Påbygg uten fagbrev',
        'Sonanspakken'
    ];

    const sortedPacks = packs.sort((a, b) => {
        const indexA = order.indexOf(a.title);
        const indexB = order.indexOf(b.title);
        return indexA - indexB;
    })

    return sortedPacks
}