export function CheckIfFullyearCourse(courseCode: string) {
    const courseCodes = [
        'NOR1267/NOR1268/NOR1269',
        'FSP6219/FSP6220',
        'FSP6222/FSP6223',
        'PSP5826/PSP5827',
        'PSP5828/PSP5829',
        'FSP6239/FSP6240',
        'FSP6242/FSP6243',
        'PSP5838/PSP5839',
        'PSP5840/PSP5841',
        'pack-MAT-online-MAT1019/MAT1023',
        'pack-MAT-online-ulike y-koder/MAT1151'
    ]

    if (courseCodes.indexOf(courseCode) !== -1) {
        return true
    } else {
        return false
    }
}

export function FindOppositeSemester(semester: string) {
    let otherSem = ''
    if (semester === 'autumn') {
        otherSem = 'spring'
    } else if (semester === 'spring') {
        otherSem = 'autumn'
    } else if (semester === 'year') {
        otherSem = 'yearspring'
    } else if (semester === 'yearspring') {
        otherSem = 'year'
    } else if (semester === 'lab_practice_autum') {
        otherSem = 'lab_practice_spring'
    } else if (semester === 'lab_practice_spring') {
        otherSem = 'lab_practice_autum'
    } else {
        otherSem = 'autumn'
    }

    return otherSem
}

export function FindOppositeIntro(semester: string) {
    let otherSem = ''
    if (semester === 'introautumn') {
        otherSem = 'introspring'
    } else {
        otherSem = 'introautumn'
    }

    return otherSem
}