import { useEffect, useState } from "react";
import { selectedCourses } from "../../../../types";
import { Button } from "../Button/Button";

type Props = {
    course: selectedCourses,
    selectedCourses: selectedCourses[];
    changeCourses: (courses: selectedCourses[]) => void;
    closeModal: () => void;
}

export function UpgradePopup({course, selectedCourses, changeCourses, closeModal}: Props){
    const [priceDiff, setPriceDiff] = useState<number>(0);
    const [title, setTitle] = useState<string>('')

    useEffect(() => {
        const extras = course.upgradeCourses;
        const semester = course.selectedSemester;
        const extraCourses = extras.filter((e) => e.time === semester)
        const selectedCourse = course.selectedCourse;
        const diff = extraCourses[0].price - selectedCourse.price;
        const courseName = extraCourses[0].courseTitle
        setPriceDiff(diff)
        setTitle(courseName)
    }, [course])

    function upgradeCourse() {
        const extras = course.upgradeCourses;
        const semester = course.selectedSemester;
        const extraCourses = extras.filter((e) => e.time === semester)
        const selectedCourse = extraCourses[0]
        let courseArr = [...selectedCourses]

        const index = courseArr.findIndex((ca) => ca.courseCode === selectedCourse.courseCode);

        if(index !== -1) {
            courseArr[index].selectedCourse = selectedCourse
            courseArr[index].selectedPrice = selectedCourse.price
            courseArr[index].upgradeCourses = []
        }
        
        changeCourses(courseArr)
        closeModal()
    }

    return (
        <div className="flex flex-col items-center gap-4">
            <p>Ønsker du å oppgradere til {title.replace('Matematikk', 'Matte')}? (+{priceDiff}kr)</p>
            <Button onClick={() => upgradeCourse()}>Endre til {title.replace('Matematikk', 'Matte')}</Button>
        </div>
    )
}