export function ShortenCourseName(name: string) {
    const ShortenedName = name.replace('Matematikk', 'Matte')
    .replace('matematikk', 'matte')
    .replace('Politikk og menneskerettigheter', 'Pol. og menneskerett.')
    .replace('Sosiologi og sosialantropologi', 'Sosiologi og sosialant.')
    .replace('Markedsføring og Ledelse', 'Markedsføring')
    .replace('påbygg VG3', 'påbygg')
    .replace('Informasjonsteknologi', 'IT')
    .replace('Forberedende til', 'Forkurs')
    .replace('Forkurs til', 'Forkurs')
    .replace('labkurs', 'lab')
    .replace('praktisk matte', 'P-/P-Y matte')
    .replace('P- og T-matematikk', 'P-/T-matte')
    .replace('PB', '')
    .replace(', BUA Vg2', '')
    .replace(', BUA prakiskandidat', '')
    .replace(', HEA praksiskandidat', '')
    .replace(', HSF Vg1', '')
    .replace(', HES Vg2', '')
    .replace(', HSE Vg3', '')
    .replace(', HEA Vg2', '')

    return ShortenedName
}