export function setSemester(semester: { title: string, semesterAmount: number, firstSemester: string, year: number }) {
    let firstSem = semester.firstSemester === 'autumn' ? 'Høst' : 'Vår'
    let secondSem = ''
    let secondYear = 0
    if (firstSem === 'Høst') {
        secondSem = 'Vår'
        secondYear = semester.year + 1
    } else {
        secondSem = 'Høst'
        secondYear = semester.year
    }

    let semesters = [];
    for (var i = 0; i < semester.semesterAmount; i++) {
        let sem = ''
        let year = semester.year
        let examString = ''
        let alias = ''

        if (Math.abs(i % 2) === 0) {
            sem = firstSem
        } else {
            sem = secondSem
        }

        if ((sem === 'Vår' && i === 1) || i >= 2) {
            year = year + 1
        }

        if (sem === 'Vår') {
            examString = 'Eksamen apr.-jun.'
            alias = 'spring'
        } else {
            examString = 'Eksamen nov.-des.'
            alias = 'autumn'
        }

        let semObj = {
            semesterTitle: `${sem} ${year}`,
            examString: examString,
            courses: [],
            semesterAlias: alias
        }

        semesters.push(semObj)

    }
    localStorage.setItem('semesters', JSON.stringify(semesters))
}