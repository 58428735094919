const GetPackInfo: { [key: string]: string[] } = {
  Sonanspakken: [
    'Bestiller du seks fag eller flere får du 15% på totalbeløpet*. Labkurs og forkurs regnes ikke som et fag, men dersom du tar det i tillegg til seks andre fag så får du 15% rabatt på hele beløpet. *Fagene kan fordeles over ett til to semester, men må bestilles samtidig.',
    'Sonanspakken gjelder på alle våre campuser, live og online.',
  ],
  'Påbygg uten fagbrev': [
    'Har du to år yrkesfag, men mangler fagbrev og studiekompetanse? Denne pakkeløsningen kan være veien til et ferdig vitnemål og studiemuligheter.',
  ],

  'Påbygg med fagbrev': [
    'Har du fagbrev eller har gått 3 år yrkesfag på videregående, kan denne pakkeløsningen være veien til generell studiekompetanse.',
    'Denne pakken passer også for deg som er lærling, og som ønsker å oppnå generell studiekompetanse underveis i lærlingløpet.',
  ],
  'Medisinpakke - Utland': [
    'Skal du studere medisin i utlandet? Dette er de vanligste fagene å ta!',
    'Opptakskravene varierer mellom ulike land og læresteder, men gode kunnskaper i realfag er uansett nødvendig. Fagkravene kan variere fra skole til skole.',
  ],
  'Medisinpakke - Norge': [
    'Opptak til medisin krever generell studiekompetanse, og nødvendige kvalifikasjoner i matematikk, fysikk og kjemi.',
  ],
  Ingeniørpakke: [
    'Realfagene du trenger for å søke ingeniørstudier.',
    'Fagene i realfagskurset dekker kravkoden for ingeniørstudier som Samordna opptak kaller HING. Kursene passer for deg som har generell studiekompetanse. Det anbefales å ha hatt Matematikk 1T.',
  ],
  '23/5-pakken': [
    'Er du 23 år eller eldre og ute etter generell studiekompetanse? Har du minst fem år med arbeidspraksis eller utdanning, trenger du bare seks fag for å kunne søke opptak til universitet eller høyskole.',
    'Når du søker høyere utdanning med 23/5-regelen, får du alderspoengførst fra og med det året du fyller 24.',
    'De fem årene du må ha hatt praksis eller utdanning, kan for eksempel være lønnet arbeid, omsorg for barn, militærtjeneste eller siviltjeneste, frivillig arbeid og utdanning fra videregående eller folkehøgskole.',
    '23/5-pakken kan fullføres på ett år, men kan også gjøres på halvannet år.',
    'Har du allerede karakter i noen av fagene, kan du ta bare enkelte av fagene i pakken (fag fra R94 godkjennes også).',
    'Skal du søke på et studie som krever realfag i tillegg? Hos oss kan du fint kombinere realfag med 23/5-pakken.',
  ],
  'Vg1 Helse og oppvekstfag': [
    'Helse og oppvekstfag Vg1 er felles for alle som vil utdanne seg innenfor ulike retninger i helse og oppvekstfag.',
    'I denne pakken får du alle programfagene du trenger fra Vg1.',
    'Merk at du i tillegg til programfagene, må ha fellesfagene samt oppfylle kravet til yrkesfaglig fordypning (YFF) på Vg1, som betyr minimum 210 timer relevant praksis innenfor helse- og oppvekstsektoren eller beståtte fag innen studieforberedende programområde. Skal YFF dekkes med studieforberedende fag må omfanget tilsvare minimum 168 årstimer. Som eksempel kan du ta to valgfrie programfag (til sammen 280 årstimer).',
  ],
  'Vg2 Helseservicefag': [
    'Ser du for deg en jobb på helsestasjon, legesenter eller sykehus? Denne pakken inneholder alle de obligatoriske programfagene til helseservice Vg2.',
    'Når du har bestått alle fellesfag og programfag til Vg2 Helseservice kan du gå videre på Vg3 med såkalte autorisasjonsfag som for eksempel Helsesekretær.',
    'Denne pakken er for deg som mangler følgende programfag fra Vg2 Helseservice:',
    'Merk at du i tillegg til programfagene, må ha fellesfagene samt oppfylle kravet til yrkesfaglig fordypning (YFF) på Vg2, som betyr minimum 316 timer relevant praksis innenfor helse- og oppvekstsektoren eller beståtte fag innen studieforberedende programområde. Skal YFF dekkes med studieforberedende fag må omfanget tilsvare minimum 253 årstimer. Som eksempel kan du ta to valgfrie programfag (til sammen 280 årstimer).',
  ],
  'Vg3 Helsesekretær': [
    'Ser du for deg en jobb på helsestasjon, legesenter eller sykehus? Å utdanne seg til helsesekretær gir deg mange muligheter.',
    'I denne pakken får du alle programfag fra Vg3 helsesekretær.',
    'Tittelen «helsesekretær» er en beskyttet tittel som krever at du søker om autorisasjon. For å få autorisasjon må du ha bestått hele Vg1 i helse og oppvekstfag, Vg2 helseservice og Vg3 Helsesekretær.',
    'Denne pakken er for deg som mangler følgende programfag fra Vg2 helsesekretær:',
  ],
  'Vg2 Helsearbeiderfag': [
    'Jobbmulighetene for helsefagarbeidere er mange, og behovet er stort innen både pleie- og omsorgsarbeid og i helse- og sosialtjenesten. Med fullført Vg1 helse og oppvekstfag og helsearbeiderfag Vg2 kvalifiserer deg for å søke om læreplass og deretter ta fagprøve.',
    'Denne pakken er for deg som mangler følgende programfag fra Vg2 Helsearbeiderfag:',
    'Merk at du i tillegg til programfagene, må ha fellesfagene samt oppfylle kravet til yrkesfaglig fordypning (YFF) på Vg2, som betyr minimum 316 timer relevant praksis innenfor helse- og oppvekstsektoren eller beståtte fag innen studieforberedende programområde. Skal YFF dekkes med studieforberedende fag må omfanget tilsvare minimum 253 årstimer. Som eksempel kan du ta to valgfrie programfag (til sammen 280 årstimer).',
  ],
  'Vg2 Barne- og ungdomsarbeider': [
    'Ønsker du å jobbe med barn og unge? Å utdanne seg til barne- og ungdomsarbeider gir deg mange jobbmuligheter innen barnehage, sykehus, skolefritidsordninger m.m.',
    'Med bestått Vg1 helse og oppvekstfag og barne- og ungdomsarbeiderfag Vg2 er du kvalifisert til å søke om læreplass og deretter ta fagprøve.',
    'Denne pakken er for deg som mangler følgende programfag fra Vg2 barne- og ungdomsarbeider:',
    'Merk at du i tillegg til programfagene, må ha fellesfagene samt oppfylle kravet til yrkesfaglig fordypning (YFF) på Vg2, som betyr minimum 316 timer relevant praksis innenfor helse- og oppvekstsektoren eller beståtte fag innen studieforberedende programområde. Skal YFF dekkes med studieforberedende fag må omfanget tilsvare minimum 253 årstimer. Som eksempel kan du ta to valgfrie programfag (til sammen 280 årstimer).',
  ],
  'Praksiskandidat Helsefagarbeider': [
    'Mangler du Vg1 og Vg2, men har arbeidserfaring innen helsefag? Da kan du med en skriftlig eksamen i dette faget kvalifisere deg for å ta fagbrev som praksiskandidat. Dette kurset forbereder deg til skriftlig eksamen i helsearbeiderfaget. Kompetansemålene er tilsvarende læreplan i Vg2 helsefagarbeider samt fordypning i henhold til kompetansemål for Vg3 – opplæring i bedrift.',
    'Merk at det er opplæringskontoret i ditt fylke som godkjenner praksisen din og slik gir deg godkjenning som praksiskandidat. Du kan få godkjent tidligere skole som en del av praksisen din på minimum 5 år.',
  ],
  'Praksiskandidat Barne- og ungdomsarbeider': [
    'Mangler du Vg1 og Vg2, men har arbeidserfaring innen barne- og ungdomsarbeid? Da kan du med en skriftlig eksamen i dette faget kvalifisere deg for å ta fagbrev som praksiskandidat. Dette kurset forbereder deg til skriftlig eksamen i barne- og ungdomsarbeiderfaget. Kompetansemålene er tilsvarende læreplan i Vg2 barne- og ungdomsarbeiderfag samt fordypning i henhold til kompetansemål for Vg3 – opplæring i bedrift.',
    'Merk at det er opplæringskontoret i ditt fylke som godkjenner praksisen din og slik gir deg godkjenning som praksiskandidat. Du kan få godkjent tidligere skole som en del av praksisen din på minimum 5 år.',
  ],
  'Matte R1+R2': ['Trenger du både matematikk R1 og R2? Denne pakken lønner seg for deg!'],
  'Matte S1+S2': ['Trenger du både matematikk S1 og S2? Denne pakken lønner seg for deg!'],
};

export default GetPackInfo;
