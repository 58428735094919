import React, { useEffect } from 'react'
import { Icon } from '../Icon/Icon';

interface ModalProps {
    isOpen: boolean;
    title?: string;
    onClose: () => void;
    children: React.ReactNode;
    id?: string;
    color?: 'standard' | 'first-color' | 'second-color' | 'third-color';
}

const Modal: React.FC<ModalProps> = ({ isOpen, title = '', onClose, children, color = 'standard', id = 'popup' }) => {
    if (!isOpen) return null;


        let style = 'border-primary-color border-4'
        if(color === 'first-color') {
            style = 'border-first-color border-4 border-l-[50px]'
        } else if(color === 'second-color') {
            style = 'border-second-color border-4 border-l-[50px]'
        } else if(color === 'third-color') {
            style = 'border-third-color border-4 border-l-[50px]'
        }


    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div onClick={() => onClose()} className="requires-no-scroll absolute inset-0 bg-black w-full h-full opacity-50"></div>
            <div id={id} className={`relative bg-white ${style} p-2 tablet-s:p-4 z-10 w-[95%] tablet-s:w-2/3 laptop-s:w-[600px] max-h-[95%] overflow-y-auto`}>
                <div className='w-full flex gap-4'>
                    <div className='w-full'>
                        <h1 className='text-lg tablet-s:text-2xl font-bold'>{title}</h1>
                    </div>
                    <div className='absolute top-0  right-0 p-2' onClick={() => onClose()}>
                        <Icon className='w-4' type='close-icon' />
                    </div>
                </div>
                <div className='px-0 tablet-s:px-4 py-4'>
                    {children}
                </div>

            </div>
        </div>
    );
};

export default Modal;