import { classes } from "../../utils/helpers";
import ArrowThinLeft from '../../../../assets/arrow-thin-l.svg'
import ArrowThinRight from '../../../../assets/arrow-thin-r.svg'
import ArrowFullLeft from '../../../../assets/arrow-full-l.svg'
import ArrowFullRight from '../../../../assets/arrow-full-r.svg'
import { Icon } from "../Icon/Icon";

type ButtonMode = 'nav-left' | 'nav-right' | 'carousel-left' | 'carousel-right'

type Props = {
    onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
    mode?: ButtonMode;
    disabled?: boolean;
    className?: string;
    arrowStyle?: string;
}

function getIconType(mode: string) {
    if (mode === 'nav-right') {
        return "arrow-thin-right"
    } else if (mode === 'carousel-left') {
        return "arrow-full-left"
    } else if (mode === 'carousel-right') {
        return "arrow-full-right"
    }
    return "arrow-thin-left"
}

export function ArrowButton({ onClick, className = '', disabled = false, mode = 'nav-left', arrowStyle = '' }: Props) {


    return (
        <div className="py-2 flex">
            <button
                onClick={onClick}
                disabled={disabled}
                className={classes(`py-4 px-4 disabled:opacity-50 mobile-s:py-2 mobile-s:px-2`,
                    mode === 'carousel-left' ? 'border border-black rounded-md bg-primary-color hover:bg-white' : '',
                    mode === 'carousel-right' ? 'border border-black rounded-md bg-primary-color hover:bg-white' : '',
                    className
                )}
            >
                <Icon type={getIconType(mode)} className={arrowStyle} />
            </button>
        </div>
    )
}