import { useEffect, useState } from "react";
import { selectedCourses, CourseVariants, IconTypes } from "../../../../types"
import { CourseInformation } from "../../../Helpers/GetText/GetText";
import { Icon } from "../Icon/Icon";
import { Button } from "../Button/Button";
import dataLayer from "../../../../utils/dataLayer";
import DOMPurify from "dompurify";
import { checkIfEarlyAccessCourse } from "../../../Helpers/GetText/libraries/GetCourseCodes";
import { getFirstSem } from "../../../Helpers/Functions/GetAvailableSemesters";
import { FindOppositeSemester } from "../../../Helpers/Functions/CheckSemester";

type Props = {
    course: selectedCourses;
    exams: { type: string, date: string }[];
    semester: string;
    selectedCourses: selectedCourses[];
    changeCourses: (courses: selectedCourses[]) => void;
    closeModal: () => void;
}

export function CoursePopup({ course, exams, semester, selectedCourses, changeCourses, closeModal }: Props) {
    const [studyLoad, setStudyLoad] = useState<number>(0);
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [showTop, setShowTop] = useState<boolean>(true);
    const [hasAutumn, setHasAutumn] = useState<boolean>(false);
    const [hasSpring, setHasSpring] = useState<boolean>(false);
    const [hasYear, setHasYear] = useState<boolean>(false);
    const [hasSpringYear, setHasSpringYear] = useState<boolean>(false);
    const [hasLabAutumn, setHasLabAutumn] = useState<boolean>(false);
    const [hasLabSpring, setHasLabSpring] = useState<boolean>(false);
    const [selectedSem, setSelectedSem] = useState<string>('')
    const [selectedTime, setSelectedTime] = useState<string>('')
    const [isPackCourse, setIsPackCourse] = useState<boolean>(false)
    const [autumnCourse, setAutumCourse] = useState<CourseVariants>({} as CourseVariants)
    const [springCourse, setSpringCourse] = useState<CourseVariants>({} as CourseVariants)
    const [yearCourse, setYearCourse] = useState<CourseVariants>({} as CourseVariants)
    const [springYearCourse, setSpringYearCourse] = useState<CourseVariants>({} as CourseVariants)
    const [autumnLabCourse, setAutumnLabCourse] = useState<CourseVariants>({} as CourseVariants)
    const [springLabCourse, setSpringLabCourse] = useState<CourseVariants>({} as CourseVariants)
    const [isUpgradeCourse, setIsUpgradeCourse] = useState<boolean>(false)
    const [upgradeCourse, setUpgradeCourse] = useState<CourseVariants>({} as CourseVariants)
    const [isPrevSem, setIsPrevSem] = useState<boolean>(false)

    const translateExamType = (examType: string) => {
        if (examType === 'oral-exam') {
            return 'Muntlig eksamen';
        } else if (examType === 'oral-lab-exam') {
            return 'Muntlig-praktisk eksamen';
        } else {
            return 'Skriftlig eksamen';
        }
    };

    useEffect(() => {
        const courseCode = course.courseCode;
        const type = course.type;
        if (courseCode.includes('intro-') || courseCode.includes('lab-')) {
            setShowTop(false);
            setShowInfo(true);
        }

        if (type !== 'online') {
            const autumn = course.variants.filter(v => v.time === 'autumn')
            const spring = course.variants.filter(v => v.time === 'spring')
            const year = course.variants.filter(v => v.time === 'year')
            const springYear = course.variants.filter(v => v.time === 'yearspring')
            const labAutumn = course.variants.filter(v => v.time === 'lab_practice_autum')
            const labSpring = course.variants.filter(v => v.time === 'lab_practice_spring')
            if (autumn.length > 0) {
                setHasAutumn(true)
                setAutumCourse(autumn[0])
            }
            if (spring.length > 0) {
                setHasSpring(true)
                setSpringCourse(spring[0])
            }
            if (year.length > 0) {
                setHasYear(true)
                setYearCourse(year[0])
            }
            if (springYear.length > 0) {
                setHasSpringYear(true)
                setSpringYearCourse(springYear[0])
            }
            if (labAutumn.length > 0) {
                setHasLabAutumn(true)
                setAutumnLabCourse(labAutumn[0])
            }
            if (labSpring.length > 0) {
                setHasLabSpring(true)
                setSpringLabCourse(labSpring[0])
            }
        } else {
            setHasAutumn(true)
            setAutumCourse(course.selectedCourse)
            setHasSpring(true)
            setSpringCourse(course.selectedCourse)
            setHasYear(true)
            setYearCourse(course.selectedCourse)
            setHasSpringYear(true)
            setSpringYearCourse(course.selectedCourse)
        }
        setSelectedSem(course.selectedSemester)
        setSelectedTime(course.selectedTime)
        setIsPackCourse(course.packCourse)
        if (course.selectedCourse.courseTitle.includes(' med teskje') || course.selectedCourse.courseTitle.includes(' bestått')) {
            setIsUpgradeCourse(true)
            setUpgradeCourse(course.selectedCourse)
        }

        // Temp logic to force certain courses to be spring only
        const isEarlyAccess = checkIfEarlyAccessCourse(course.courseCode)
        if(isEarlyAccess && type === 'online'){
            setHasAutumn(false)
            setHasYear(false)
            setHasSpringYear(false)
        }
        // End of temp logic

    }, [course])



    useEffect(() => {
        const hours = course.selectedCourse.yearHours;
        const percent = Math.trunc((hours / 420) * 100);
        gtagViewItem();
        setStudyLoad(percent);
        const isPrevSem = JSON.parse(localStorage.getItem('prevSem') || 'false')
        setIsPrevSem(isPrevSem)
    }, []);

    function gtagViewItem() {
        const event = {
            event: 'view_item',
            currency: 'NOK',
            value: course.selectedPrice,
            items: [
                {
                    item_name: course.selectedCourse.courseTitle,
                    discount: 0,
                    item_type: course.selectedCourse.time,
                    item_brand: "Sonans",
                    item_category: 'fag',
                    item_id: course.selectedCourse.courseId + course.selectedCourse.time,
                    price: course.selectedCourse.price,
                    quantity: 1
                }
            ]
        }

        dataLayer.push(event)
    }

    function switchSemester(semester: string, newCourse: CourseVariants) {
        let courseArr = [...selectedCourses]
        const index = courseArr.findIndex((ca) => ca.courseCode === newCourse.courseCode);
        let price = newCourse.price
        if (newCourse.time === 'netbased') {
            if (semester === 'year' || semester === 'yearspring') {
                price = newCourse.priceNetFullyear
            } else {
                price = newCourse.priceNetHalfyear
            }
        }

        if (index !== -1) {
            courseArr[index].selectedCourse = newCourse
            courseArr[index].selectedPrice = price
            courseArr[index].selectedTime = newCourse.time
            courseArr[index].selectedSemester = semester
            courseArr[index].earlyStart = false
            courseArr[index].manuallyMoved = true
            if (isUpgradeCourse) {
                courseArr[index].upgradeCourses = [upgradeCourse]
            }
        }

        changeCourses(courseArr)
        closeModal()
    }

    function getExamDate(type: string, semester: string, prevSem: boolean, date: string) {
        const firstSem = getFirstSem();
        const secondSem = FindOppositeSemester(firstSem)
        if(semester === firstSem && !prevSem) {
            return date
        } else if(semester !== firstSem && type !== 'written-exam' && !prevSem) {
            return 'Du får dato av privatistkontoret'
        } else if(semester !== firstSem && type === 'written-exam' && !prevSem) {
            return 'Dato kommer'
        } else if(semester === secondSem && isPrevSem) {
            return date
        } else {
            return date
        }
    }

    return (
        <div className="flex flex-col gap-4 justify-center px-2 w-full">
            {showTop && <ol className="list-none text-left">
                {selectedSem === 'yearspring' && <li className="flex gap-1 mb-2"><Icon className="w-4" type="arrow-thin-right" /> DEL 1 - Faget avsluttes høsten 2025</li>}
                {exams.map((e, i) => (
                    <li className="flex gap-1 mb-2" key={`${e.type}-${course.courseCode}-${i}`}><Icon className="w-6" type={e.type as IconTypes} /><span className="flex flex-col tablet-s:gap-2 tablet-s:flex-row">
                        <span className="font-semibold">{translateExamType(e.type)}:</span>
                        <span className="text-sm tablet-s:text-base">{getExamDate(e.type, semester, isPrevSem, e.date)}</span>
                    </span></li>
                ))}
                <li className="font-semibold">Fagkode: {course.courseCode.replace('pack-MAT-online-', '')}</li>
                <li className="font-semibold">Studiebelastning: {studyLoad}%</li>
            </ol>}

            {showTop && <div className="w-full flex justify-start hover:cursor-pointer" onClick={() => setShowInfo(!showInfo)}>
                <div className="flex justify-center gap-4">
                    <p>{showInfo === true ? 'les mindre' : 'les mer'}</p>
                    <Icon className={`w-2 ${showInfo ? 'rotate-[90deg]' : 'rotate-[270deg]'}`} type='arrow-full-left' />
                </div>
            </div>}
            {showInfo && <ol className="list-disc text-left">
                {CourseInformation(course.courseCode).map((info, i) => {
                    const sanitizedInfo = typeof info === 'string' ? DOMPurify.sanitize(info) : '';

                    return (
                        <li
                            key={`${course.courseCode}-extra-info-${i}`}
                            dangerouslySetInnerHTML={{ __html: sanitizedInfo }}
                        />
                    );
                })}

            </ol>}
            {!isUpgradeCourse && <div className="flex gap-2">
                {hasAutumn && selectedSem !== 'autumn' && <Button onClick={() => switchSemester('autumn', autumnCourse)}>Flytt til høst</Button>}
                {hasSpring && selectedSem !== 'spring' && <Button onClick={() => switchSemester('spring', springCourse)}>Flytt til vår</Button>}
                {hasYear && selectedSem !== 'year' && selectedSem === 'autumn' && <Button onClick={() => switchSemester('year', yearCourse)}>Endre til helår</Button>}
                {hasSpringYear && selectedSem !== 'yearspring' && selectedSem === 'spring' && <Button onClick={() => switchSemester('yearspring', springYearCourse)}>Endre til helår</Button>}
                {hasLabAutumn && selectedSem !== 'lab_practice_autum' && <Button onClick={() => switchSemester('lab_practice_autum', autumnLabCourse)}>Flytt til høst</Button>}
                {hasLabSpring && selectedSem !== 'lab_practice_spring' && <Button onClick={() => switchSemester('lab_practice_spring', autumnLabCourse)}>Flytt til vår</Button>}
            </div>}
            {isUpgradeCourse && <div className="flex gap-2">
                {hasAutumn && selectedSem === 'autumn' && <Button onClick={() => switchSemester('autumn', autumnCourse)}>Endre til {course.selectedCourse.courseTitle.replace('Matematikk', 'Matte').replace('matematikk', 'matte').replace(' med teskje', '').replace(' bestått', '')}</Button>}
                {hasSpring && selectedSem === 'spring' && <Button onClick={() => switchSemester('spring', springCourse)}>Endre til {course.selectedCourse.courseTitle.replace('Matematikk', 'Matte').replace('matematikk', 'matte').replace(' med teskje', '').replace(' bestått', '')}</Button>}
                {hasYear && selectedSem === 'year' && <Button onClick={() => switchSemester('year', yearCourse)}>Endre til {course.selectedCourse.courseTitle.replace('Matematikk', 'Matte').replace('matematikk', 'matte').replace(' med teskje', '').replace(' bestått', '')}</Button>}
            </div>}
        </div>
    );
}