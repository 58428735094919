import { useEffect, useState } from 'react';
import { Icon } from '../Icon/Icon';
import { CourseVariants, StructuredCourse } from '../../../../types';
import { CourseLine } from './CourseLine';
import { classes } from '../../utils/helpers';
import { SelectedCourses } from './SelectedCourses';
import { Button } from '../Button/Button';
import { selectedCourses, IconTypes } from '../../../../types';
import { getCourseType, getSelectedCourse } from '../../../Helpers/CourseSearch/CourseSearchHelpers';
import { CheckIfFullyearCourse } from '../../../Helpers/Functions/CheckSemester';
import dataLayer from '../../../../utils/dataLayer';
import { checkIfEarlyAccessCourse } from '../../../Helpers/GetText/libraries/GetCourseCodes';
import { twJoin } from 'tailwind-merge';
import { vocationalCourses } from '../../../Helpers/StructureData/StructureData';

type Props = {
  semesterAlias: string;
  courseArr: StructuredCourse[];
  addCourses: (selections: selectedCourses[]) => void;
  setSelections: (selections: selectedCourses[]) => void;
  closeModal: () => void;
  courseSelections: selectedCourses[];
};

export function CourseSearch({
  semesterAlias,
  courseArr,
  addCourses,
  courseSelections,
  closeModal,
  setSelections,
}: Props) {
  const [courses, setCourses] = useState<StructuredCourse[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [sortedCourses, setSortedCourses] = useState<StructuredCourse[]>([]);
  const [selectedCampus, setSelectedCampus] = useState<string>('');
  const [campusTitle, setCampusTitle] = useState<string>('');
  const [schoolType, setSchoolType] = useState<string>('');
  const [semAlias, setSemAlias] = useState<string>('');
  const [selCourseArr, setSelCourseArr] = useState<selectedCourses[]>([]);
  const [originalSelections, setOriginalSelections] = useState<selectedCourses[]>([]);
  const [isPrevSem, setIsPrevSem] = useState<boolean>(false);
  const [filter, setFilter] = useState<'' | 'generalStudies' | 'vocationalStudies'>('');

  function sortCourses(campus: string, schoolType: string) {
    let sorted: StructuredCourse[] = [];

    if (schoolType === 'online') {
      courses.map((c) => {
        if (c.places.indexOf('sonans') !== -1 || c.vocationalSubject) {
          sorted.push(c);
        }
      });
    } else if (schoolType === 'live') {
      courses.map((c) => {
        if (c.places.indexOf('sondig') !== -1 || c.places.indexOf('sonans') !== -1) {
          sorted.push(c);
        }
      });
    } else if (schoolType === 'campus') {
      courses.map((c) => {
        if (c.places.indexOf(campus) !== -1 || c.places.indexOf('sondig') !== -1 || c.places.indexOf('sonans') !== -1) {
          sorted.push(c);
        }
      });
    } else {
      courses.map((c) => {
        if (c.places.indexOf(campus) !== -1 || c.places.indexOf('sondig') !== -1 || c.places.indexOf('sonans') !== -1) {
          sorted.push(c);
        }
      });
    }

    sorted = sorted.sort((a, b) => {
      if (a.courseTitle.toLowerCase() < b.courseTitle.toLowerCase()) return -1;
      if (a.courseTitle.toLowerCase() > b.courseTitle.toLowerCase()) return 1;
      return 0;
    });

    return sorted;
  }

  function searchCourses(event: any) {
    const searchVal: string = event.target.value;
    const sorted = sortCourses(selectedCampus, schoolType);
    setSearchText(searchVal);

    const filteredItems = sorted.filter((course) =>
      course.courseTitle
        .toLowerCase()
        .includes(
          searchVal
            .replace('matte', 'matematikk')
            .replace('matt', 'matematikk')
            .replace('Matte', 'matematikk')
            .replace('Matt', 'matematikk')
            .replace('1PY', '1p-y')
            .replace('1py', '1p-y')
            .replace('2PY', '2p-y')
            .replace('2py', '2p-y')
            .replace('it', 'informasjonsteknologi')
            .replace('IT', 'informasjonsteknologi')
            .replace('It', 'informasjonsteknologi')
            .replace('PB', '')
            .replace('gym', 'kroppsøving')
            .replace('Gym', 'kroppsøving')
            .toLocaleLowerCase()
        )
    );

    setSortedCourses(filteredItems);
  }

  function selectCourse(course: StructuredCourse, location: string, remove: boolean = false) {
    let type: IconTypes = getCourseType(location);
    let selectedSem = location !== 'sonans' ? semesterAlias : 'netbased';

    const isFullYear = CheckIfFullyearCourse(course.courseCode);

    let selCourse = getSelectedCourse(course, location, selectedSem);
    let selSemester = selCourse.time;

    let selPrice = selCourse.price;
    if (isFullYear && location === 'sonans') {
      selPrice = selCourse.priceNetFullyear;
      selSemester = 'year';
    } else if (location === 'sonans') {
      selPrice = selCourse.priceNetHalfyear;
      selSemester = semesterAlias;
    }

    const isEarlyAccess = checkIfEarlyAccessCourse(course.courseCode);
    if (isEarlyAccess && location === 'sonans') {
      selPrice = selCourse.priceNetHalfyear;
      selSemester = 'spring';
    }

    let selTime = selCourse.time;

    const variants = course.variants.filter((variant) => variant.places.indexOf(location) !== -1);
    const lab = course.labCourses.filter((lab) => lab.places.indexOf(location) !== -1);
    const upgrades = course.upgradeCourses.filter((upgrade) => upgrade.places.indexOf(location) !== -1);
    let upgradeTimes: string[] = [];
    upgrades.map((u) => {
      upgradeTimes.push(u.time);
    });

    const selectedCourse: selectedCourses = {
      courseCode: course.courseCode,
      type: type,
      course: course,
      selectedSemester: selSemester,
      selectedCourse: selCourse,
      selectedPrice: selPrice,
      selectedTime: selTime,
      variants: variants,
      labCourses: lab,
      upgradeCourses: upgrades,
      upgradeTimes: upgradeTimes,
      packCourse: false,
      pack: '',
      packTitle: '',
    };

    let courseArr = [...selCourseArr];
    const index = courseArr.findIndex((ca) => ca.courseCode === course.courseCode);

    if (index !== -1) {
      if (
        courseArr[index].type === type &&
        courseArr[index].selectedCourse === selectedCourse.selectedCourse &&
        courseArr[index].selectedSemester === selSemester &&
        courseArr[index].packCourse === false
      ) {
        // Course with the same type and course code already exists, remove it
        courseArr.splice(index, 1);
      } else if (remove) {
        courseArr.splice(index, 1);
      } else if (courseArr[index].packCourse === false) {
        // Course with the same course code but different type exists, update the type
        courseArr[index].type = type;
        courseArr[index].selectedCourse = selectedCourse.selectedCourse;
        courseArr[index].selectedPrice = selPrice;
        courseArr[index].upgradeCourses = upgrades;
        courseArr[index].selectedSemester = selSemester;
        courseArr[index].selectedTime = selTime;
      }
    } else {
      courseArr.push(selectedCourse);
      const addEvent = {
        event: 'add_to_cart',
        currency: 'NOK',
        value: selectedCourse.selectedPrice,
        items: [
          {
            item_name: selectedCourse.selectedCourse.courseTitle,
            discount: 0,
            item_type: selectedCourse.selectedCourse.time,
            item_brand: 'Sonans',
            item_category: 'fag',
            item_id: selectedCourse.selectedCourse.courseId + selectedCourse.selectedCourse.time,
            price: selectedCourse.selectedCourse.price,
            quantity: 1,
          },
        ],
      };
      dataLayer.push(addEvent);
    }

    setSelCourseArr(courseArr);
    setSelections(courseArr);
  }

  function saveSelections() {
    addCourses(selCourseArr);
    closeModal();
  }

  useEffect(() => {
    let campus: string = localStorage.getItem('schoolAlias') || '';
    let campusTitle: string = localStorage.getItem('schoolName') || '';
    let schoolType: string = localStorage.getItem('schoolType') || '';
    setSelectedCampus(campus);
    setCampusTitle(campusTitle);
    setSchoolType(schoolType);
    let sorted = sortCourses(campus, schoolType);
    setSortedCourses(sorted);
    const isPrevSem = JSON.parse(localStorage.getItem('prevSem') || 'false');
    setIsPrevSem(isPrevSem);
  }, [courses]);

  useEffect(() => {
    setSemAlias(semesterAlias);
  }, [semesterAlias]);

  useEffect(() => {
    setCourses(courseArr);
  }, [courseArr]);

  useEffect(() => {
    setSelCourseArr(courseSelections);
    setOriginalSelections(courseSelections);
    setSelections(courseSelections);
  }, [courseSelections]);

  const generalStudiesCourses = sortedCourses.filter((course) => !course.vocationalSubject);
  const vocationalStudiesCourses = sortedCourses.filter((course) => course.vocationalSubject);

  const vocationalCategories = vocationalCourses.map((course) => course.name);

  return (
    <div>
      <div className='w-full'>
        <div className='flex justify-between items-baseline'>
          <input
            type='search'
            placeholder='Søk'
            name='searchField'
            className='border p-2 w-full'
            value={searchText}
            onChange={(e) => searchCourses(e)}
          />
          <div className='w-[155px]'>
            <p className='font-semibold text-base w-[155px] justify-center'>Velg sted:</p>
          </div>
        </div>
        <div className='flex gap-2 py-4 items-center w-full xl:w-[380px] 2xl:w-[385px] desktop:w-[373px]'>
          <svg className='w-10' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M8 18V12H10V14H18V16H10V18H8ZM0 16V14H6V16H0ZM4 12V10H0V8H4V6H6V12H4ZM8 10V8H18V10H8ZM12 6V0H14V2H18V4H14V6H12ZM0 4V2H10V4H0Z'
              fill='#1C1B1F'
            />
          </svg>
          <button
            onClick={() => {
              if (filter === 'generalStudies') {
                setFilter('');
              } else {
                setFilter('generalStudies');
              }
            }}
            className={twJoin(
              'text-base w-full rounded-lg border border-black py-1 lg:py-2 px-4 shadow-md',
              filter === 'generalStudies' ? 'bg-sonans-primary' : 'bg-white'
            )}>
            Studiespesialiserende
          </button>
          <button
            onClick={() => {
              if (filter === 'vocationalStudies') {
                setFilter('');
              } else {
                setFilter('vocationalStudies');
              }
            }}
            className={twJoin(
              'text-base laptop:text-base w-full rounded-lg border border-black py-1 lg:py-2 px-4 shadow-md',
              filter === 'vocationalStudies' ? 'bg-sonans-primary' : 'bg-white'
            )}>
            Yrkesfag
          </button>
        </div>
      </div>
      <div className='w-full h-40 laptop-m:h-96 mt-2 overflow-x-auto mb-5'>
        {(filter === '' || filter === 'generalStudies') && generalStudiesCourses.length > 0 && (
          <div className='flex flex-col'>
            {generalStudiesCourses.map((course, i) => (
              <CourseLine
                key={course.courseCode + '-sorted-courses-' + i}
                course={course}
                currentSemester={semesterAlias}
                schoolType={schoolType}
                selectedCampus={selectedCampus}
                selectCourse={selectCourse}
                selCourseArr={selCourseArr}
              />
            ))}
          </div>
        )}
        {(filter === '' || filter === 'vocationalStudies') && vocationalStudiesCourses.length > 0 && (
          <div className='flex flex-col'>
            {vocationalCategories.map((category) => (
              <div key={category}>
                {vocationalStudiesCourses.filter((c) => c.vocationalCategory === category).length > 0 && (
                  <p className='font-bold text-left pl-2 py-4'>{category}</p>
                )}
                {vocationalStudiesCourses
                  .filter((c) => c.vocationalCategory === category)
                  .map((course, i) => (
                    <CourseLine
                      key={course.courseCode + '-sorted-courses-' + i}
                      course={course}
                      currentSemester={semesterAlias}
                      schoolType={schoolType}
                      selectedCampus={selectedCampus}
                      selectCourse={selectCourse}
                      selCourseArr={selCourseArr}
                    />
                  ))}
              </div>
            ))}
          </div>
        )}
        {sortedCourses.length === 0 && (
          <div className='flex justify-center'>
            <p>
              Vi kunne desverre ikke finne fag med søkeordet "<i>{searchText}</i>"
            </p>
          </div>
        )}
      </div>
      {isPrevSem && schoolType === 'kombi' && semesterAlias === 'spring' && (
        <div className='border-4 rounded-lg border-third-color-outline bg-third-color flex flex-col mobile-s:text-sm justify-center p-2 mobile-s:p-1 mt-2 mb-2 tablet-s:mt-4 tablet-s:mb-4'>
          <p>Våren 2024: Meld deg på live- og onlinekurs her.</p>
          <p>
            For campus, ring{' '}
            <a id='call_help_button' className='underline font-semibold' href='tel:+4791504070'>
              915 040 70
            </a>
            .
          </p>
        </div>
      )}
      <SelectedCourses
        selectCourse={selectCourse}
        selCourseArr={selCourseArr}
        selectedSchool={campusTitle}
        currentSem={semesterAlias}
      />
      <div className='flex justify-end mb-2'>
        <Button mode='dark' onClick={() => saveSelections()}>
          Ok
        </Button>
      </div>
    </div>
  );
}
