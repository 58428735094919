import { Fragment, useEffect, useState } from 'react';
import CloseIcon from '../../../../assets/close-icon.svg'
import { CourseVariants, IconTypes, StructuredCourse, selectedCourses } from '../../../../types';
import { Icon } from '../Icon/Icon'
import { CourseRequirements, ExamArray, PrevExamArray } from '../../../Helpers/GetText/GetText';
import Modal from '../Modal/Modal';
import { CoursePopup } from './CoursePopup';
import { UpgradePopup } from './UpgradePopup';
import FormatPrice from '../../../Helpers/Functions/FormatPrice';
import { Button } from '../Button/Button';
import { getCourseType, getSelectedCourse } from '../../../Helpers/CourseSearch/CourseSearchHelpers';
import dataLayer from '../../../../utils/dataLayer';
import { EarlyStartModal } from '../ModalContents/EarlyStartModal';
import { checkIfEarlyAccessCourse } from '../../../Helpers/GetText/libraries/GetCourseCodes';
import { ShortenCourseName } from '../../../Helpers/Functions/ShortenCourseName';



type Props = {
    isExtra?: boolean;
    course: selectedCourses;
    selectedCourses: selectedCourses[];
    semester: string;
    removeCourse: (course: selectedCourses) => void;
    changeCourses: (courses: selectedCourses[]) => void;
}

interface CourseRequirements {
    courseCodes: string[];
    courseNames: string[];
}

export function CourseCard({ isExtra = false, course, semester, removeCourse, changeCourses, selectedCourses }: Props) {
    const [color, setColor] = useState<string>('');
    const [exams, setExams] = useState<{ type: string, date: string }[]>([]);
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false)
    const [requirementModalOpen, setRequirementModalOpen] = useState<boolean>(false)
    const [removePackModal, setRemovePackModal] = useState<boolean>(false)
    const [requirements, setRequirements] = useState<CourseRequirements[]>()
    const [reqNotMet, setReqNotMet] = useState<boolean>(false)
    const [isSonansPack, setIsSonansPack] = useState<boolean>(false)
    const [hasLab, setHasLab] = useState<boolean>(false)
    const [hasSelectedLab, setHasSelectedLab] = useState<boolean>(false)
    const [missingReq, setMissingReq] = useState<boolean>(false)
    const [labCourses, setLabCourses] = useState<CourseVariants[]>([])
    const [openStartToday, setOpenStartToday] = useState<boolean>(false)
    const [openNormalStart, setOpenNormalStart] = useState<boolean>(false)
    const [isPrevSem, setIsPrevSem] = useState<boolean>(false)


    function handleCloseModal() {
        setModalOpen(false)
    }

    function handleOpenModal() {
        setModalOpen(true)
    }

    function handleCloseUpgradeModal() {
        setUpgradeModalOpen(false)
    }

    function handleOpenUpgradeModal() {
        setUpgradeModalOpen(true)
    }

    function handleCloseRequirementModal() {
        setRequirementModalOpen(false)
    }

    function handleOpenRequirementModal() {
        setRequirementModalOpen(true)
    }

    function handleOpenRemovePackModal() {
        setRemovePackModal(true)
    }

    function handleCloseRemovePackModal() {
        setRemovePackModal(false)
    }

    function handleOpenStartTodayModal() {
        setOpenStartToday(true)
    }

    function handleCloseStartTodayModal() {
        setOpenStartToday(false)
    }

    function handleOpenNormalStartModal() {
        setOpenNormalStart(true)
    }

    function handleCloseNormalStartModal() {
        setOpenNormalStart(false)
    }

    let cardClasses = "border relative rounded-lg shadow-md w-full flex bg-white h-[90px]"
    if (course.packCourse === true) {
        cardClasses = `border-2 rounded-lg shadow-md w-full relative border-${color} flex bg-white h-[90px]`
    }
    let courseNameClasses = "font-semibold text-xs tablet-s:text-sm tablet-m:text-xl"


    if (isExtra === true) {
        cardClasses = "border relative flex bg-bg-grey"
        courseNameClasses = "font-semibold text-xl text-text-grey"
    }

    useEffect(() => {
        const isPrevSem = JSON.parse(localStorage.getItem('prevSem') || 'false')
        setIsPrevSem(isPrevSem)
    }, [])

    useEffect(() => {
        const req = CourseRequirements(course.courseCode)
        const selectedCourseCodes: string[] = []
        selectedCourses.forEach((sel) => {
            selectedCourseCodes.push(sel.courseCode)
        })

        if (req) {
            const found = selectedCourseCodes.some(cc => req[0].courseCodes.includes(cc))
            if (!found) {
                setReqNotMet(true)
            } else {
                setReqNotMet(false)
            }
        }
        const fullCourses = selectedCourses.filter(sc => sc.packCourse === false && !sc.courseCode.includes('intro') && !sc.courseCode.includes('lab'))
        if (fullCourses.length > 5) {
            setIsSonansPack(true)
        } else {
            setIsSonansPack(false)
        }
    }, [selectedCourses])

    useEffect(() => {
        const category = course.type;
        if (category === 'campus') {
            setColor('first-color')
        } else if (category === 'live') {
            setColor('second-color')
        } else if (category === 'online') {
            setColor('third-color')
        }

        let exams = ExamArray(course.courseCode);
        const isPrevSem = JSON.parse(localStorage.getItem('prevSem') || 'false')
        if (isPrevSem && semester === 'spring') {
            exams = PrevExamArray(course.courseCode);
        }
        setExams(exams)
        const req = CourseRequirements(course.courseCode)
        setRequirements(req)

        // Commented out auto switch to earlyStart on intro courses
        /* if(course.type === 'online' && course.courseCode.includes('intro') && course.selectedSemester === 'autumn' && !course.earlyStart) {
            upgradePreCourse()
        } */
    }, [course])

    useEffect(() => {
        const courseCode = `lab-${course.courseCode}`
        const labCourses = course.labCourses
        setLabCourses(labCourses)
        if (labCourses.length > 0) {
            setHasLab(true)
            const selectedLabs = selectedCourses.filter((sel) => sel.courseCode === courseCode)
            if (selectedLabs.length > 0) {
                setHasSelectedLab(true)
            } else {
                setHasSelectedLab(false)
            }
        }

    }, [course, selectedCourses])

    useEffect(() => {
        if (!reqNotMet && hasSelectedLab) {
            setMissingReq(false)
        } else if (reqNotMet || (hasLab && !hasSelectedLab)) {
            setMissingReq(true)
        } else {
            setMissingReq(false)
        }
    }, [hasLab, hasSelectedLab, reqNotMet])

    function addLab() {
        const selSemester = course.selectedSemester === 'spring' ? 'lab_practice_spring' : 'lab_practice_autum'
        const semesterLab = labCourses.filter((l) => l.time === selSemester)
        const location: string = localStorage.getItem('schoolAlias') || ''
        let type: IconTypes = getCourseType(location)
        let times: string[] = []
        semesterLab.forEach((sem) => {
            times.push(sem.time)
        })

        const labCourse: StructuredCourse = {
            courseCode: semesterLab[0].courseCode,
            courseTitle: semesterLab[0].courseTitle,
            labCourses: [],
            places: semesterLab[0].places,
            times: times,
            upgradeCourses: [],
            upgradePlaces: [],
            variants: labCourses,
            vocationalCategory: '',
            vocationalSubject: false
        }

        const selCourse = getSelectedCourse(labCourse, location, course.selectedSemester)
        const selPrice = selCourse.price;
        let selTime = selCourse.time;
        const variants = labCourse.variants.filter((variant) => variant.places.indexOf(location) !== -1)

        const selectedLab: selectedCourses = {
            courseCode: labCourse.courseCode,
            type: type,
            course: labCourse,
            selectedSemester: selSemester,
            selectedCourse: semesterLab[0],
            selectedPrice: selPrice,
            selectedTime: selTime,
            variants: variants,
            labCourses: [],
            upgradeCourses: [],
            upgradeTimes: [],
            packCourse: false,
            pack: '',
            packTitle: ''
        }

        let courseArr = [...selectedCourses];
        courseArr.push(selectedLab);
        const addEvent = {
            event: 'add_to_cart',
            currency: 'NOK',
            value: selectedLab.selectedPrice,
            items: [
                {
                    item_name: selectedLab.selectedCourse.courseTitle,
                    discount: 0,
                    item_type: selectedLab.selectedCourse.time,
                    item_brand: 'Sonans',
                    item_category: 'fag',
                    item_id: selectedLab.selectedCourse.courseId + selectedLab.selectedCourse.time,
                    price: selectedLab.selectedCourse.price,
                    quantity: 1
                }
            ]
        }

        dataLayer.push(addEvent);
        changeCourses(courseArr);
        handleCloseRequirementModal();
    }

    function upgradePreCourse() {
        const dateToday = new Date();
        const year = dateToday.getFullYear();
        const dateCutoff = new Date(year, 6, 1);
        if (dateToday < dateCutoff && !course.earlyStart) {
            let courseArr = [...selectedCourses]
            const index = courseArr.findIndex((ca) => ca.courseCode === course.courseCode);
            if (index !== -1) {
                courseArr[index].earlyStart = true;
            }

            changeCourses(courseArr)
        }

    }

    function upgradeStartToday() {
        let courseArr = [...selectedCourses]
        const index = courseArr.findIndex((ca) => ca.courseCode === course.courseCode);
        let price = course.selectedCourse.priceNetFullyear;
        if (index !== -1) {
            courseArr[index].selectedPrice = price;
            courseArr[index].selectedSemester = 'yearspring';
            courseArr[index].earlyStart = true;
        }

        changeCourses(courseArr)
        handleCloseStartTodayModal()
    }

    function changeNormalStart() {
        let courseArr = [...selectedCourses]
        const index = courseArr.findIndex((ca) => ca.courseCode === course.courseCode);
        let price = course.selectedCourse.priceNetHalfyear;
        if (index !== -1) {
            courseArr[index].selectedPrice = price;
            courseArr[index].selectedSemester = 'autumn';
            courseArr[index].earlyStart = false;
        }

        changeCourses(courseArr)
        handleCloseNormalStartModal()
    }

    return (
        <Fragment key={course.courseCode + '-card'}>
            <div className='flex justify-between w-full gap-1'>
                {exams && (course.selectedSemester === 'yearspring' && course.earlyStart === true || course.selectedSemester !== 'yearspring' || course.selectedSemester === 'yearspring' && isPrevSem) && (<div onClick={() => handleOpenModal()} className={`w-[35px] tablet-s:w-[40px] gap-1 bg-${color} rounded-lg shadow-md border-2 border-${color}-outline flex flex-col justify-center items-center p-2 cursor-pointer`}>
                    {exams.map((e, i) => (
                        <Icon key={course.courseCode + '-card-exam-' + i} className='w-5' type={e.type as IconTypes} />
                    ))}
                </div>)}
                {(course.selectedSemester === 'yearspring' && !isPrevSem && course.earlyStart !== true) && <div onClick={() => handleOpenModal()} className={`w-[35px] tablet-s:w-[40px] gap-1 bg-${color} border-2 border-${color}-outline rounded-lg shadow-md flex flex-col justify-center items-center p-1 cursor-pointer`}>
                    <Icon className='w-4' type={'arrow-thin-right'} />
                    <p className=' text-xs whitespace-nowrap font-semibold'>Del 1</p>
                </div>}
                <div className={`${cardClasses} flex`}>
                    <div className="flex flex-col tablet-s:flex-row text-left p-1 w-full cursor-pointer gap-2">
                        <div className='flex flex-col w-full'>
                            <div className='flex gap-2'>
                                <p onClick={() => handleOpenModal()} className={courseNameClasses}>{ShortenCourseName(course.selectedCourse.courseTitle)}</p>
                                {missingReq && <div key={`${course.courseCode}-req`} onClick={() => handleOpenRequirementModal()}>
                                    <p className='text-xs mb-1 mr-1 tablet-s:text-base font-semibold underline'>OBS!</p>
                                </div>}
                            </div>
                            {(!course.packCourse && !isSonansPack) && <p onClick={() => handleOpenModal()} className="text-text-grey">{FormatPrice(course.selectedPrice)},-</p>}
                            {(course.packCourse || isSonansPack) && <p onClick={() => handleOpenModal()} className="text-text-grey"><span className='line-through'>{FormatPrice(course.selectedPrice)},-</span></p>}
                        </div>

                        {/*  {course.type === 'online' && course.selectedSemester === 'autumn' && !course.courseCode.includes('intro') && !isPrevSem && <div onClick={() => handleOpenStartTodayModal()} className='w-full tablet-s:text-right text-left mr-2'>
                            <p className='text-xs tablet-s:text-base font-semibold underline'>Vil du starte i dag?</p>
                        </div>}

                        {course.type === 'online' && (course.selectedSemester === 'autumn' || course.selectedSemester === 'yearspring') && course.earlyStart === true && !course.courseCode.includes('intro') && <div onClick={() => handleOpenNormalStartModal()} className='w-full tablet-s:text-right text-left mr-2 flex gap-1 items-baseline justify-start tablet-s:justify-end'>
                            <div className='flex gap-1 px-1'>
                                <Icon className='w-4' type="check" />
                                <p className='text-xs tablet-s:text-base font-semibold underline'>Tilgang i dag!</p>
                            </div>
                        </div>} */}

                        {course.type === 'online' && (course.selectedSemester === 'autumn' || course.selectedSemester === 'year') && checkIfEarlyAccessCourse(course.courseCode) && <div className='w-full tablet-s:text-right text-left mr-2 flex gap-1 items-baseline justify-start tablet-s:justify-end'>
                            <div className='flex gap-1 px-1 bg-third-color'>
                                <p className='text-xs tablet-s:text-base font-semibold'>Tilgang i dag!</p>
                            </div>
                        </div>}

                        {course.upgradeCourses.length > 0 && course.upgradeTimes.indexOf(semester) !== -1 && <div onClick={() => handleOpenUpgradeModal()} className='hover:cursor-pointer flex gap-1 items-center self-baseline tablet-s:self-end'>
                            <Icon className='w-4 tablet-s:w-6' type='plus-bubble' />
                            <p className='font-semibold text-xs tablet-s:text-sm underline w-full'>Ekstratimer</p>
                        </div>}
                    </div>
                    <div className="flex flex-col justify-self-end justify-between h-full w-auto">
                        <div className='flex justify-end'>
                            <img onClick={() => removeCourse(course)} className='w-[14px] h-[14px] tablet-s:w-[16px] tablet-s:h-[16px] m-2 hover:cursor-pointer' src={CloseIcon} />
                        </div>
                    </div>
                </div>
            </div>
            <Modal id='course_popup' title={course.selectedCourse.courseTitle.replace('Matematikk', 'Matte').replace('matematikk', 'matte')} color={color as 'standard' | 'first-color' | 'second-color' | 'third-color'} isOpen={modalOpen} onClose={() => handleCloseModal()}>
                <CoursePopup course={course} selectedCourses={selectedCourses} changeCourses={(courses) => changeCourses(courses)} exams={exams} closeModal={() => handleCloseModal()} semester={semester} />
            </Modal>
            <Modal id='course_upgrade_popup' title='Dette faget tilbys med 1 ekstra undervisningstime hver uke!' isOpen={upgradeModalOpen} onClose={() => handleCloseUpgradeModal()}>
                <UpgradePopup selectedCourses={selectedCourses} changeCourses={(courses) => changeCourses(courses)} closeModal={() => handleCloseUpgradeModal()} course={course} />
            </Modal>
            <Modal id='course_requirements_popup' title={`Viktig info om ${course.selectedCourse.courseTitle}`} isOpen={requirementModalOpen} onClose={() => handleCloseRequirementModal()}>
                <div>
                    {reqNotMet && requirements && <p className='text-lg font-semibold'>Påkrevde forkunnskaper:</p>}
                    {reqNotMet && requirements && <p className='mb-5'>{requirements[0].courseNames.map((r, i) => i > 0 ? ` eller ${r}` : r)}</p>}
                    {hasLab && !hasSelectedLab && <p className='text-lg font-semibold'>Labkurs:</p>}
                    {hasLab && !hasSelectedLab && <p className='mb-5'>Labøvelser er en del av muntlig-praktisk eksamen i dette faget, og legger grunnlaget for en tredjedel av din karakter. Labkurset er ikke obligatorisk, men vi anbefaler at du tar det for å være best mulig forberedt.</p>}
                    {hasLab && !hasSelectedLab && <Button onClick={() => addLab()} mode='dark'>Legg til lab</Button>}
                </div>
            </Modal>
            <Modal title={'Faget er del av en pakke'} isOpen={removePackModal} onClose={() => handleCloseRemovePackModal()}>
                <div className='flex flex-col justify-center gap-4'>
                    <div>
                        <p>Fjerner du dette faget så vil du fjerne hele pakken</p>
                        <p>Er du sikker på at du vil fjerne pakken?</p>
                    </div>
                    <div className='flex gap-4 justify-center'>
                        <Button onClick={() => removeCourse(course)}>Ja</Button>
                        <Button onClick={() => handleCloseRemovePackModal()}>Nei</Button>
                    </div>
                </div>
            </Modal>

            <Modal title={'Starte på nettkurs i dag?'} isOpen={openStartToday} onClose={() => handleCloseStartTodayModal()}>
                <div className='flex flex-col justify-center gap-4'>
                    <div>
                        <p>Høstsemesteret starter fra 1. juli. Du kan velge å få tilgang til kurset allerede i dag.</p>
                    </div>
                    <div>
                        <p>Ønsker du å oppgradere til tidlig tilgang? (+ {course.selectedCourse.priceNetFullyear - course.selectedCourse.priceNetHalfyear},-)</p>
                    </div>
                    <div className='flex justify-center'>
                        <Button onClick={() => upgradeStartToday()} className='flex items-baseline gap-2'><Icon type='check' />Oppgrader tidlig tilgang</Button>
                    </div>
                </div>
            </Modal>

            <Modal id="campaign_popup" color="third-color" title={'Semesterstart fra 1. juli?'} isOpen={openNormalStart} onClose={() => handleCloseNormalStartModal()}>
                <div className='flex flex-col justify-center gap-4'>
                    <div>
                        <p>Høstsemesteret starter fra 1. juli.</p>
                    </div>
                    <div>
                        <p>Ønsker du å endre til ordinær oppstart? (- {course.selectedCourse.priceNetFullyear - course.selectedCourse.priceNetHalfyear},-)</p>
                    </div>
                    <div className='flex justify-center'>
                        <Button onClick={() => changeNormalStart()} className='flex items-baseline gap-2'><Icon type='check' />Endre til ordinær oppstart</Button>
                    </div>
                </div>
                {/* <EarlyStartModal /> */}
            </Modal>

        </Fragment>
    )
}