import { useEffect, useState } from "react";
import { PageTitle } from "../../../UI";
import { IPackageCoverage, PackStructure, selectedCourses } from "../../../../types";
import { PackLine } from "../../Verification/pages/PackLine";
import { VerificationLine } from "../../Verification/pages/VerificationLine";
import FormatPrice from "../../../Helpers/Functions/FormatPrice";
import { beginCheckoutGTAG } from "../../../Helpers/GTAG/checkout";

export function ConfirmPage() {
    const [allCourses, setAllCourses] = useState<selectedCourses[]>([])
    const [courses, setCourses] = useState<selectedCourses[]>([])
    const [packCourses, setPackCourses] = useState<selectedCourses[]>([])
    const [totalPrice, setTotalPrice] = useState<number>(0)
    const [totalFull, setTotalFull] = useState<number>(0)
    const [sonansPack, setSonansPack] = useState<boolean>(false)
    const [packs, setPacks] = useState<PackStructure[]>([])
    const [packCoverage, setPackCoverage] = useState<IPackageCoverage | null>(null)

    useEffect(() => {
        SendCheckoutToGTAG(courses, packs, totalPrice)
    }, [courses, packs, packCourses, totalPrice, totalFull])

    useEffect(() => {
        let total = 0;
        let totalFull = 0;
        allCourses.forEach((c) => {
            if(c.packCourse === false) {
                total += c.selectedPrice
            }
            totalFull += c.selectedPrice
        })

        if(packCoverage && packCoverage.priceAddedCourses && packCoverage.savingsIfAdded) {
            total += packCoverage.priceAddedCourses - packCoverage.savingsIfAdded
        }

        // const fullCourses = courses.filter((c) => c.packCourse === false && (!c.courseCode.includes('intro-' || 'lab-')));
        const fullCourses = courses.filter((c) => c.packCourse === false && (!c.courseCode.includes('intro-') && !c.courseCode.includes('lab-')));

        let packAlias = ''
        if (packs.length > 0) {
            packAlias = packs[0].alias
        }
        

        if (fullCourses.length > 5 || packAlias === 'sonanspack') {
            setSonansPack(true)
        }
        setTotalPrice(total);
        setTotalFull(totalFull)
    }, [allCourses, packCoverage])

    useEffect(() => {
        const courseList = JSON.parse(localStorage.getItem('selectedCourses') || '[]')
        const pack = JSON.parse(localStorage.getItem('selectedPack') || '[]')
        const packList = courseList.filter((c: selectedCourses) => c.packCourse === true && c.pack !== 'sonanspack')
        const courses = courseList.filter((c: selectedCourses) => c.packCourse === false || c.packCourse == true && c.pack === 'sonanspack')
        const packCover = JSON.parse(localStorage.getItem('packCoverage') || '[]')
        setAllCourses(courseList)
        setPackCoverage(packCover)
        setPackCourses(packList)
        setCourses(courses)
        setPacks(pack)
    }, [])

    function SendCheckoutToGTAG(courses: selectedCourses[], packs: PackStructure[], totalPrice: number) {
        const saleCompleted = JSON.parse(localStorage.getItem('completed_sale') || 'false')
        if(!saleCompleted) {
            beginCheckoutGTAG(courses, packs, 'purchase', totalPrice)
            localStorage.setItem('completed_sale', 'true')
        }
    }

    return (
        <div>
            <PageTitle title="Takk for din påmelding!" />
            <div className="flex flex-col justify-center text-lg">
                <p>Har du spørsmål kan du kontakte oss på <a className="underline" href="tel:91504070">91504070</a> eller sende oss en <a className="underline" href="mailto:kundesenter@sonans.no">e-post</a>.</p>
                <p>Dersom du har huket av for at du ønsker å snakke med en rådgiver vil vi ringe deg innen én virkedag.</p>
            </div>
            <div className='flex flex-col divide-y-2 mt-8'>
                {packs.map((p) => (
                    p.alias !== 'sonanspack' &&
                    <PackLine key={`${p.alias}-pack`} pack={p} />
                ))}
                {packCourses.map((c) => (
                    <VerificationLine key={c.courseCode + '-verification-line-pc'} course={c} />
                ))}
                {packCourses.length > 0 && courses.length > 0 && <div className=' bg-primary-color-light p-2'>
                    <p className='text-left text-lg font-bold'>Øvrige fag:</p>
                </div>}
                {courses.map((c) => (
                    <VerificationLine key={c.courseCode + '-verification-line-c'} course={c} />
                ))}
                {sonansPack && <div className=' bg-primary-color-light w-full flex justify-between p-2'>
                    <p className='text-left text-lg font-bold'>Sonanspakken</p>
                    <p className="w-full text-right font-semibold">15% rabatt</p>
                </div>}
            </div>
            <div className='w-full border-b-2 border-black border-dashed mt-8 mb-8'></div>
            {totalPrice !== totalFull && <div className='w-full flex pl-2 pr-2'>
                <p className='w-full text-right text-xl font-semibold'><span className='text-sm line-through text-text-grey'>{FormatPrice(totalFull)},-</span></p>
            </div>}
            <div className='w-full flex pl-2 pr-2'>
                <p className='text-xl font-semibold w-full text-left'>Totalt</p>
                <p className='w-full text-right text-xl font-semibold'><span className='bg-third-color'>{FormatPrice(totalPrice)},-</span></p>
            </div>
            <p className='text-left pl-2 pr-2'>*I tillegg til kursavgiften vil det påløpet en semesteravgift på 975,- per semester. Dette gjelder ikke ved kjøp av kun labkurs eller forkurs.</p>
        </div>
    )
}