import { useEffect, useState } from "react";
import { selectedCourses } from "../../../../types"
import { CourseCard } from "../CourseCard/CourseCard";
import { getChosenSemVariants } from "../../../Helpers/CourseSearch/CourseSearchHelpers";

type Props = {
    courses: selectedCourses[];
    semester: string;
    selectCourses: (selections: selectedCourses[]) => void;
    removePacks: () => void;
}

export function SemesterCourses({courses, semester, selectCourses, removePacks}: Props) {
    const [courseList, setCourseList] = useState<selectedCourses[]>([])

    useEffect(() => {
        let shownSemesters = getChosenSemVariants(semester)
        const isPrevSem = JSON.parse(localStorage.getItem('prevSem') || 'false')

        let selected = courses.filter((c) => shownSemesters.indexOf(c.selectedSemester) !== -1 || (semester === 'spring' && c.selectedSemester === 'yearspring' && c.earlyStart !== true && !isPrevSem) || (semester === 'autumn' && c.selectedSemester === 'yearspring' && c.earlyStart === true))

        setCourseList(selected)

    }, [courses, semester])

    function removeCourse(course: selectedCourses) {
        let courseArr = [...courses]
        /* if(course.packCourse === true) {
            courseArr = courseArr.filter((c) => c.packCourse !== true)
            removePacks()
        } */
        const index = courseArr.findIndex((c) => c.courseCode === course.courseCode)
        if(index !== -1) {
            courseArr.splice(index, 1)
        }
        selectCourses(courseArr)
    }

    function changeCourses(courses: selectedCourses[]) {
        selectCourses(courses)
    }

    

    return(
        <div key={semester} className="h-full flex flex-col gap-2 px-2 pb-2">
            {courseList.map((c) => (
                <CourseCard key={c.courseCode + '-semester-courses-' + semester} selectedCourses={courses} course={c} semester={semester} removeCourse={(course) => removeCourse(course)} changeCourses={(courses) => changeCourses(courses)}/>
            ))}
        </div>
    )
}