import React, { LegacyRef, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Button, PageTitle } from '../../../UI';
import './formPage.css';
import { PackStructure, StructuredCourse, selectedCourses } from '../../../../types';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

export function Form() {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [pid, setPid] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [confirmEmail, setConfirmEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [postCode, setPostCode] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [contact, setContact] = useState<boolean>(false);
  const [accepted, setAccepted] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [pack, setPack] = useState<PackStructure[]>([]);
  const [courses, setCourses] = useState<selectedCourses[]>([]);
  const [schoolAlias, setSchoolAlias] = useState<string>('');
  const [contractAlias, setContractAlias] = useState<string>('');
  const [allCourses, setAllcourses] = useState<selectedCourses[]>([]);
  const [lockSubmit, setLockSubmit] = useState<boolean>(true);
  const [isPrevSem, setIsPrevSem] = useState<boolean>(false);
  const [recaptcha, setRecaptcha] = useState<string>('');

  const captchaRef = useRef<ReCAPTCHA>(null);

  const navigate = useNavigate();

  function sendForm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLockSubmit(true);

    const prevSems = ['yearspring', 'spring', 'lab_practice_spring', 'introspring'];
    event.preventDefault();
    if (email !== confirmEmail) {
      setErrorMessage('Epostene matcher ikke');
      return;
    }
    if (allCourses.length === 0) {
      setErrorMessage('Du må legge fag i handlekurven for å bestille');
      return;
    }
    const formData = new FormData();
    formData.append('name', `${firstName} ${lastName}`);
    formData.append('birth_number', pid);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('address', address);
    formData.append('zipcode', postCode);
    formData.append('city', city);
    formData.append('seeking_guidance', contact === true ? 'yes' : 'no');
    formData.append('cansign', accepted === true ? 'yes' : 'no');
    formData.append('paymentOption', 'invoice-mail');
    formData.append('user_comment', '');
    formData.append('click_id', 'false');
    allCourses.forEach((c, i) => {
      formData.append(`course_order[${i}][course_id]`, c.selectedCourse.courseId);
      formData.append(`course_order[${i}][time]`, c.selectedTime);
      formData.append(`course_order[${i}][alternative_type]`, c.selectedSemester);
      formData.append(`course_order[${i}][netcourse]`, c.selectedTime === 'netbased' ? 'true' : 'false');
      // if (c.earlyStart || (isPrevSem && prevSems.indexOf(c.selectedSemester) !== -1)) {
      //     formData.append(`course_order[${i}][earlyStart]`, 'true')
      // }
      if (c.packCourse === true && c.pack !== 'sonanspack') {
        formData.append(`course_order[${i}][ispackage]`, '1');
      }
      formData.append(`course_order[${i}][classLetter]`, '');
      formData.append(`course_order[${i}][semester]`, '2015/2016');
    });
    pack.forEach((p) => {
      if (p.alias !== 'sonanspack') {
        formData.append('package_order', p.lookup.text);
      }
    });

    const url = `${process.env.REACT_APP_WEB_API_URL}order/${contractAlias}/account`;

    if (!recaptcha) return;

    formData.append('captcha-token', recaptcha);

    axios({
      method: 'post',
      url: url,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response) {
        //handle success
        setLockSubmit(false);
        navigate('/bekreftet');
      })
      .catch(function (response) {
        captchaRef.current?.reset();
        //handle error
        setErrorMessage('Noe gikk galt, sjekk informasjon og prøv igjen.');
        setLockSubmit(false);
      });
  }

  function changePid(event: SyntheticEvent) {
    const inputElement = event.target as HTMLInputElement;
    const inputType = (event as unknown as InputEvent).inputType;

    if (inputType === 'deleteContentBackward' || inputElement.value.match(/^\d*$/)) {
      setPid(inputElement.value);
    }
  }

  function changeNumber(event: SyntheticEvent) {
    const inputElement = event.target as HTMLInputElement;
    const inputType = (event as unknown as InputEvent).inputType;

    if (inputType === 'deleteContentBackward' || inputElement.value.match(/^\d*$/)) {
      setPhone(inputElement.value);
      if (inputElement.value.length !== 8) {
        setLockSubmit(true);
      }
    }
  }

  function changeZip(event: SyntheticEvent) {
    const inputElement = event.target as HTMLInputElement;
    const inputType = (event as unknown as InputEvent).inputType;

    if (inputType === 'deleteContentBackward' || inputElement.value.match(/^\d*$/)) {
      setPostCode(inputElement.value);
    }
  }

  function AliasCorrection(courses: selectedCourses[], packs: PackStructure[], alias: string) {
    let courseTypes: string[] = [];
    courses.forEach((c, i) => {
      if (courseTypes.indexOf(c.type) === -1) {
        courseTypes.push(c.type);
      }
    });
    packs.forEach((p, i) => {
      if (courseTypes.indexOf(p.placement) === -1) {
        courseTypes.push(p.placement);
      }
    });
    let contractType = alias;
    if (courseTypes.indexOf('campus') !== -1) {
      contractType = alias;
    } else if (courseTypes.indexOf('live') !== -1) {
      contractType = 'sondig';
    } else {
      contractType = 'sonng';
    }
    setContractAlias(contractType);
  }

  useEffect(() => {
    const courses = JSON.parse(localStorage.getItem('selectedCourses') || '[]');
    const packs = JSON.parse(localStorage.getItem('selectedPack') || '[]');
    let campus: string = localStorage.getItem('schoolAlias') || '';
    const normalCourses = courses.filter((c: selectedCourses) => c.packCourse === false);
    setPack(packs);
    setSchoolAlias(campus);
    setCourses(normalCourses);
    setAllcourses(courses);
    AliasCorrection(normalCourses, packs, campus);
    const isPrevSem = JSON.parse(localStorage.getItem('prevSem') || 'false');
    setIsPrevSem(isPrevSem);
  }, []);

  useEffect(() => {
    setErrorMessage('');

    const isValid =
      firstName &&
      lastName &&
      pid &&
      email &&
      confirmEmail &&
      phone.length === 8 &&
      address &&
      postCode &&
      city &&
      accepted &&
      recaptcha;

    if (isValid) {
      setLockSubmit(false);
    }
  }, [firstName, lastName, pid, email, confirmEmail, phone, address, postCode, city, accepted, recaptcha]);

  return (
    <div>
      <PageTitle title='Kontaktinformasjon' />
      <form onSubmit={sendForm}>
        <div className='formPage'>
          {errorMessage !== '' && (
            <div className='border border-second-color-outline bg-pink-transparent'>{errorMessage}</div>
          )}
          <div>
            <input
              name='firstName'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              type='text'
              placeholder='Fornavn'
              required
            />
            <input
              name='lastName'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              type='text'
              placeholder='Etternavn'
              required
            />
          </div>
          <div>
            <input
              name='pid'
              value={pid}
              onChange={(e) => changePid(e)}
              type='text'
              pattern='^[0-9]*$'
              minLength={11}
              maxLength={11}
              placeholder='Fødselsnummer'
              required
            />
          </div>
          <div>
            <input
              name='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type='email'
              placeholder='E-post'
              required
            />
            <input
              name='confirmEmail'
              value={confirmEmail}
              onChange={(e) => setConfirmEmail(e.target.value)}
              type='email'
              placeholder='Bekreft e-post'
              required
            />
          </div>
          <div>
            <input
              name='phone'
              value={phone}
              onChange={(e) => changeNumber(e)}
              type='text'
              pattern='^[0-9][0-9]*$'
              minLength={8}
              maxLength={8}
              placeholder='Mobilnummer'
              required
            />
          </div>
          <div>
            <input
              name='address'
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              type='text'
              placeholder='Adresse'
              required
            />
          </div>
          <div>
            <input
              name='postCode'
              value={postCode}
              onChange={(e) => changeZip(e)}
              type='text'
              pattern='[0-9]*'
              minLength={4}
              maxLength={4}
              placeholder='Postnummer'
              required
            />
            <input
              name='city'
              value={city}
              onChange={(e) => setCity(e.target.value)}
              type='text'
              placeholder='Poststed'
              required
            />
          </div>
          <div>
            <div className='flex gap-4'>
              <label>
                <input
                  name='contact'
                  checked={contact}
                  onChange={(e) => setContact(e.target.checked)}
                  type='checkbox'
                />
                Jeg ønsker at rådgiver ringer meg for en gjennomgang av mitt fagvalg
              </label>
            </div>
          </div>
          <div>
            <div className='border border-second-color-outline bg-pink-transparent p-4 flex gap-4 w-full tablet-s:w-2/3'>
              <label>
                <input
                  name='accepted'
                  checked={accepted}
                  onChange={(e) => setAccepted(e.target.checked)}
                  type='checkbox'
                  required
                />
                Jeg har lest og{' '}
                <a className='underline' target='_blank' href='https://sonans.no/vilkar-og-betingelser'>
                  godtatt vilkårene
                </a>{' '}
                for bestilling av fag hos Sonans. Jeg godtar{' '}
                <a className='underline' target='_blank' href='https://sonans.no/vilkar-og-betingelser'>
                  vilkårene
                </a>{' '}
                derav 14 dagers angrerett, bindende påmelding og eventuelle avbruddsgebyr.
              </label>
            </div>
          </div>
          <ReCAPTCHA
            className='[&>div]:mx-auto'
            ref={captchaRef}
            onChange={(value) => {
              if (!value) return;

              setRecaptcha(value);
            }}
            sitekey='6LfpljQqAAAAAPuqgmaAFee21KLzNtAcYP2DZvdW'
          />
          <Button disabled={lockSubmit} type='submit' mode='dark'>
            Send bestilling
          </Button>
        </div>
      </form>
    </div>
  );
}
