const GetExamArray: { [key: string]: { type: string; date: string }[] } = {
  'NOR1267/NOR1268/NOR1269': [
    { type: 'written-exam', date: '19. nov.' },
    { type: 'written-exam', date: '18. nov.' },
    { type: 'oral-exam', date: 'Du får dato av privatistkontoret' },
  ],
  SAM3073: [{ type: 'written-exam', date: '22.nov.' }],
  REL1003: [{ type: 'oral-exam', date: 'Du får dato av privatistkontoret' }],
  NAT1018: [{ type: 'oral-lab-exam', date: 'Du får dato av privatistkontoret' }],
  NAT1007: [{ type: 'oral-lab-exam', date: 'Du får dato av privatistkontoret' }],
  REA3062: [{ type: 'written-exam', date: '20. nov.' }],
  REA3058: [{ type: 'written-exam', date: '20. nov.' }],
  MAT1151: [{ type: 'written-exam', date: '28. nov.' }],
  KRO1019: [{ type: 'oral-lab-exam', date: 'Du får dato av privatistkontoret' }],
  'REA3046/REA3047': [
    { type: 'written-exam', date: '15. nov.' },
    { type: 'oral-lab-exam', date: 'Du får dato av privatistkontoret' },
  ],
  HIS1010: [{ type: 'oral-exam', date: 'Du får dato av privatistkontoret' }],
  HIS1011: [{ type: 'oral-exam', date: 'Du får dato av privatistkontoret' }],
  'REA3043/REA3044': [
    { type: 'written-exam', date: '22. nov.' },
    { type: 'oral-lab-exam', date: 'Du får dato av privatistkontoret' },
  ],
  'REA3039/REA3040': [
    { type: 'written-exam', date: '25. nov.' },
    { type: 'oral-lab-exam', date: 'Du får dato av privatistkontoret' },
  ],
  'REA3036/REA3037': [
    { type: 'written-exam', date: '21. nov.' },
    { type: 'oral-lab-exam', date: 'Du får dato av privatistkontoret' },
  ],
  SAM3055: [{ type: 'written-exam', date: '29. nov.' }],
  SAM3058: [{ type: 'written-exam', date: '26. nov.' }],
  'REA3049/REA3050': [
    { type: 'written-exam', date: '22. nov.' },
    { type: 'oral-lab-exam', date: 'Du får dato av privatistkontoret' },
  ],
  'SPR3031/SPR3032': [
    { type: 'written-exam', date: '14. nov.' },
    { type: 'oral-exam', date: 'Du får dato av privatistkontoret' },
  ],
  MAT1019: [{ type: 'written-exam', date: '20. nov.' }],
  SAM3051: [{ type: 'written-exam', date: '26. nov.' }],
  SAM3052: [{ type: 'oral-exam', date: 'Du får dato av privatistkontoret' }],
  SAM3053: [{ type: 'oral-exam', date: 'Du får dato av privatistkontoret' }],
  'MAT1019/MAT1023': [
    { type: 'written-exam', date: '20. nov.' },
    { type: 'written-exam', date: '28. nov.' },
  ],
  'ulike y-koder/MAT1151': [
    { type: 'written-exam', date: '20. nov.' },
    { type: 'written-exam', date: '28. nov.' },
  ],
  REA3048: [{ type: 'oral-lab-exam', date: 'Du får dato av privatistkontoret' }],
  'SPR3029/SPR3030': [
    { type: 'oral-exam', date: 'Du får dato av privatistkontoret' },
    { type: 'written-exam', date: '14. nov.' },
  ],
  'ulike y-koder': [{ type: 'written-exam', date: '20. nov.' }],
  SAK1001: [{ type: 'oral-exam', date: 'Du får dato av privatistkontoret' }],
  'FSP6222/FSP6223': [
    { type: 'oral-exam', date: 'Du får dato av privatistkontoret' },
    { type: 'written-exam', date: '21. nov.' },
  ],
  SAM3054: [{ type: 'oral-exam', date: 'Du får dato av privatistkontoret' }],
  MAT1021: [{ type: 'written-exam', date: '20. nov.' }],
  REA3060: [{ type: 'written-exam', date: '14. nov.' }],
  REA3045: [{ type: 'oral-lab-exam', date: 'Du får dato av privatistkontoret' }],
  REA3042: [{ type: 'oral-lab-exam', date: 'Du får dato av privatistkontoret' }],
  REA3056: [{ type: 'written-exam', date: '14. nov.' }],
  REA3038: [{ type: 'oral-lab-exam', date: 'Du får dato av privatistkontoret' }],
  REA3035: [{ type: 'oral-lab-exam', date: 'Du får dato av privatistkontoret' }],
  MAT1023: [{ type: 'written-exam', date: '28. nov.' }],
  SAM3072: [{ type: 'oral-exam', date: 'Du får dato av privatistkontoret' }],
  SAM3057: [{ type: 'oral-exam', date: 'Du får dato av privatistkontoret' }],
  'PSP5828/PSP5829': [
    { type: 'oral-exam', date: 'Du får dato av privatistkontoret' },
    { type: 'written-exam', date: '21. nov.' },
  ],
  'PSP5826/PSP5827': [
    { type: 'oral-exam', date: 'Du får dato av privatistkontoret' },
    { type: 'written-exam', date: '20. nov.' },
  ],
  'PSP5838/PSP5839': [
    { type: 'oral-exam', date: 'Du får dato av privatistkontoret' },
    { type: 'written-exam', date: '20. nov.' },
  ],
  'FSP6239/FSP6240': [
    { type: 'oral-exam', date: 'Du får dato av privatistkontoret' },
    { type: 'written-exam', date: '20. nov.' },
  ],
  GEO1003: [{ type: 'oral-exam', date: 'Du får dato av privatistkontoret' }],
  'ENG1007/ENG1008': [
    { type: 'oral-exam', date: 'Du får dato av privatistkontoret' },
    { type: 'written-exam', date: '27. nov.' },
  ],
  NOR1269: [{ type: 'oral-exam', date: 'Du får dato av privatistkontoret' }],
  'NOR1267/NOR1268': [
    { type: 'written-exam', date: '19. nov.' },
    { type: 'written-exam', date: '18. nov.' },
  ],
  NOR1268: [{ type: 'written-exam', date: '18. nov.' }],
  'REA3056/REA3058': [
    { type: 'written-exam', date: '14. nov.' },
    { type: 'written-exam', date: '20. nov.' },
  ],
  'PSP5840/PSP5841': [
    { type: 'oral-exam', date: 'Du får dato av privatistkontoret' },
    { type: 'written-exam', date: '21. nov.' },
  ],
  'FSP6242/FSP6243': [
    { type: 'oral-exam', date: 'Du får dato av privatistkontoret' },
    { type: 'written-exam', date: '21. nov.' },
  ],
  'FSP6219/FSP6220': [
    { type: 'oral-exam', date: 'Du får dato av privatistkontoret' },
    { type: 'written-exam', date: '20. nov.' },
  ],
  'pack-MAT-online-MAT1019/MAT1023': [
    { type: 'written-exam', date: '20. nov.' },
    { type: 'written-exam', date: '28. nov.' },
  ],
  'pack-MAT-online-ulike y-koder/MAT1151': [
    { type: 'written-exam', date: '20. nov.' },
    { type: 'written-exam', date: '28. nov.' },
  ],
  'FSP6226/FSP6227': [
    { type: 'oral-exam', date: 'Du får dato av privatistkontoret' },
    { type: 'written-exam', date: '21. nov.' },
  ],
  'PSP5830/PSP5831': [
    { type: 'oral-exam', date: 'Du får dato av privatistkontoret' },
    { type: 'written-exam', date: '22. nov.' },
  ],
  'PSP5842/PSP5843': [
    { type: 'oral-exam', date: 'Du får dato av privatistkontoret' },
    { type: 'written-exam', date: '22. nov.' },
  ],
  'NOR1420/NOR1421': [
    { type: 'oral-exam', date: 'Du får dato av privatistkontoret' },
    { type: 'written-exam', date: '19. nov.' },
  ],
  HSF1006: [{ type: 'written-exam', date: '15. nov.' }],
  HSF1007: [{ type: 'written-exam', date: '21. nov.' }],
  HSF1008: [{ type: 'written-exam', date: '27. nov.' }],
  HSF1009: [{ type: 'oral-lab-exam', date: 'Du får dato av privatistkontoret' }],
  HES2005: [{ type: 'written-exam', date: '19. nov.' }],
  HES2006: [{ type: 'written-exam', date: '21. nov.' }],
  HES2007: [{ type: 'written-exam', date: '22. nov.' }],
  HES2008: [{ type: 'oral-lab-exam', date: 'Du får dato av privatistkontoret' }],
  HSE3005: [{ type: 'written-exam', date: '27. nov.' }],
  HSE3006: [{ type: 'written-exam', date: '26. nov' }],
  HSE3007: [{ type: 'written-exam', date: '21. nov.' }],
  HSE3008: [{ type: 'oral-lab-exam', date: 'Du får dato av privatistkontoret' }],
  HEA2005: [{ type: 'written-exam', date: '19. nov.' }],
  HEA2006: [{ type: 'written-exam', date: '21. nov' }],
  HEA2007: [{ type: 'written-exam', date: '22. nov.' }],
  HEA2008: [{ type: 'oral-lab-exam', date: 'Du får dato av privatistkontoret' }],
  BUA2005: [{ type: 'written-exam', date: '19. nov.' }],
  BUA2006: [{ type: 'written-exam', date: '21. nov' }],
  BUA2007: [{ type: 'written-exam', date: '22. nov.' }],
  BUA2008: [{ type: 'oral-lab-exam', date: 'Du får dato av privatistkontoret' }],
  HEA3103: [{ type: 'written-exam', date: '26. nov.' }],
  BUA3103: [{ type: 'written-exam', date: '26. nov.' }],
};

export default GetExamArray;
