type PackCourse = {
    coursecode: string;
    semester: string;
    place: string;
};

type PackSemesters = {
    [semester: string]: PackCourse[];
};

type PackLocation = {
    [location: string]: PackSemesters;
};

type PackName = {
    [packName: string]: PackLocation;
};

type Packs = {
    [packKey: string]: PackName;
};

const packs: Packs = {
    "Helsefag VG1": {},
    '23/5-pakken': {
        sonpriosl: {
            spring: {
                courses: [
                    {
                        coursecode: 'ENG1007/ENG1008',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'MAT1019',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'SAK1001',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'MAT1023',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'NAT1007',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-NAT1007',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'HIS1010',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'NOR1267/NOR1268/NOR1269',
                        semester: 'yearspring',
                        place: 'campus',
                    },
                ],
            },
        },
        campus: {
            spring: {
                courses: [
                    {
                        coursecode: 'ENG1007/ENG1008',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'MAT1019',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'SAK1001',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'MAT1023',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'NAT1007',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-NAT1007',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'HIS1010',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'NOR1267/NOR1268/NOR1269',
                        semester: 'yearspring',
                        place: 'online',
                    },
                ],
            },
            autumn: {
                courses: [
                    {
                        coursecode: 'ENG1007/ENG1008',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'MAT1019',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'SAK1001',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'MAT1023',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'NAT1007',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-NAT1007',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'HIS1010',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'NOR1267/NOR1268/NOR1269',
                        semester: 'year',
                        place: 'campus',
                    },
                ],
            },
        },
        sondig: {
            spring: {
                courses: [
                    {
                        coursecode: 'ENG1007/ENG1008',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'MAT1019',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'SAK1001',
                        semester: 'autumn',
                        place: 'live',
                    },
                    {
                        coursecode: 'MAT1023',
                        semester: 'autumn',
                        place: 'live',
                    },
                    {
                        coursecode: 'NAT1007',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-NAT1007',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'HIS1010',
                        semester: 'autumn',
                        place: 'live',
                    },
                    {
                        coursecode: 'NOR1267/NOR1268/NOR1269',
                        semester: 'yearspring',
                        place: 'online',
                    },
                ],
            },
            autumn: {
                courses: [
                    {
                        coursecode: 'ENG1007/ENG1008',
                        semester: 'autumn',
                        place: 'live',
                    },
                    {
                        coursecode: 'MAT1019',
                        semester: 'autumn',
                        place: 'live',
                    },
                    {
                        coursecode: 'SAK1001',
                        semester: 'autumn',
                        place: 'live',
                    },
                    {
                        coursecode: 'MAT1023',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'NAT1007',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-NAT1007',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'HIS1010',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'NOR1267/NOR1268/NOR1269',
                        semester: 'year',
                        place: 'live',
                    },
                ],
            },
        },
        sonans: {
            spring: {
                courses: [
                    {
                        coursecode: 'ENG1007/ENG1008',
                        semester: 'spring',
                        place: 'online',
                    },
                    {
                        coursecode: 'MAT1019',
                        semester: 'spring',
                        place: 'online',
                    },
                    {
                        coursecode: 'intro-MAT-online-p-p-y',
                        semester: 'spring',
                        place: 'online',
                    },
                    {
                        coursecode: 'SAK1001',
                        semester: 'spring',
                        place: 'online',
                    },
                    {
                        coursecode: 'MAT1023',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'NAT1007',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'HIS1011',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'NOR1267/NOR1268/NOR1269',
                        semester: 'yearspring',
                        place: 'online',
                    },
                ],
            },
            autumn: {
                courses: [
                    {
                        coursecode: 'ENG1007/ENG1008',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'intro-MAT-online-p-p-y',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'MAT1019',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'SAK1001',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'MAT1023',
                        semester: 'spring',
                        place: 'online',
                    },
                    {
                        coursecode: 'NAT1007',
                        semester: 'spring',
                        place: 'online',
                    },
                    {
                        coursecode: 'HIS1011',
                        semester: 'spring',
                        place: 'online',
                    },
                    {
                        coursecode: 'NOR1267/NOR1268/NOR1269',
                        semester: 'year',
                        place: 'online',
                    },
                ],
            },
        },
    },
    'Påbygg uten fagbrev': {
        sonpriosl: {
            spring: {
                courses: [
                    {
                        coursecode: 'MAT1151',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'KRO1019',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'SAM3054',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'NAT1007',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-NAT1007',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                    {
                        coursecode: 'HIS1010',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'NOR1267/NOR1268/NOR1269',
                        semester: 'yearspring',
                        place: 'campus',
                    },
                ],
            },
        },
        campus: {
            spring: {
                courses: [
                    {
                        coursecode: 'MAT1151',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'KRO1019',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'SAM3054',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'NAT1007',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-NAT1007',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                    {
                        coursecode: 'HIS1010',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'NOR1267/NOR1268/NOR1269',
                        semester: 'yearspring',
                        place: 'online',
                    },
                ],
            },
            autumn: {
                courses: [
                    {
                        coursecode: 'MAT1151',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'KRO1019',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'SAM3054',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'NAT1007',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-NAT1007',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                    {
                        coursecode: 'HIS1010',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'NOR1267/NOR1268/NOR1269',
                        semester: 'year',
                        place: 'campus',
                    },
                ],
            },
        },
        sondig: {
            spring: {
                courses: [
                    {
                        coursecode: 'MAT1151',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'KRO1019',
                        semester: 'autumn',
                        place: 'live',
                    },
                    {
                        coursecode: 'SAM3054',
                        semester: 'autumn',
                        place: 'live',
                    },
                    {
                        coursecode: 'NAT1007',
                        semester: 'autumn',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-NAT1007',
                        semester: 'lab_practice_autum',
                        place: 'live',
                    },
                    {
                        coursecode: 'HIS1010',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'NOR1267/NOR1268/NOR1269',
                        semester: 'yearspring',
                        place: 'online',
                    },
                ],
            },
            autumn: {
                courses: [
                    {
                        coursecode: 'MAT1151',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'KRO1019',
                        semester: 'autumn',
                        place: 'live',
                    },
                    {
                        coursecode: 'SAM3054',
                        semester: 'autumn',
                        place: 'live',
                    },
                    {
                        coursecode: 'NAT1007',
                        semester: 'autumn',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-NAT1007',
                        semester: 'lab_practice_autum',
                        place: 'live',
                    },
                    {
                        coursecode: 'HIS1010',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'NOR1267/NOR1268/NOR1269',
                        semester: 'year',
                        place: 'live',
                    },
                ],
            },
        },
        sonans: {
            spring: {
                courses: [
                    {
                        coursecode: 'MAT1151',
                        semester: 'yearspring',
                        place: 'online',
                    },
                    {
                        coursecode: 'intro-MAT-online-p-p-y',
                        semester: 'spring',
                        place: 'online',
                    },
                    {
                        coursecode: 'KRO1019',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'SAM3054',
                        semester: 'spring',
                        place: 'online',
                    },
                    {
                        coursecode: 'NAT1007',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'HIS1011',
                        semester: 'spring',
                        place: 'online',
                    },
                    {
                        coursecode: 'NOR1267/NOR1268/NOR1269',
                        semester: 'yearspring',
                        place: 'online',
                    },
                ],
            },
            autumn: {
                courses: [
                    {
                        coursecode: 'MAT1151',
                        semester: 'year',
                        place: 'online',
                    },
                    {
                        coursecode: 'intro-MAT-online-p-p-y',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'KRO1019',
                        semester: 'spring',
                        place: 'online',
                    },
                    {
                        coursecode: 'SAM3054',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'NAT1018',
                        semester: 'spring',
                        place: 'online',
                    },
                    {
                        coursecode: 'HIS1011',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'NOR1267/NOR1268/NOR1269',
                        semester: 'year',
                        place: 'online',
                    },
                ],
            },
        },
    },
    'Påbygg med fagbrev': {
        sonpriosl: {
            spring: {
                courses: [
                    {
                        coursecode: 'MAT1151',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'NAT1007',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-NAT1007',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'HIS1010',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'NOR1267/NOR1268/NOR1269',
                        semester: 'yearspring',
                        place: 'campus',
                    },
                ],
            },
        },
        campus: {
            spring: {
                courses: [
                    {
                        coursecode: 'MAT1151',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'NAT1007',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-NAT1007',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'HIS1010',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'NOR1267/NOR1268/NOR1269',
                        semester: 'yearspring',
                        place: 'online',
                    },
                ],
            },
            autumn: {
                courses: [
                    {
                        coursecode: 'MAT1151',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'NAT1007',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-NAT1007',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                    {
                        coursecode: 'HIS1010',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'NOR1267/NOR1268/NOR1269',
                        semester: 'year',
                        place: 'campus',
                    },
                ],
            },
        },
        sondig: {
            spring: {
                courses: [
                    {
                        coursecode: 'MAT1151',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'NAT1007',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-NAT1007',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'HIS1010',
                        semester: 'autumn',
                        place: 'live',
                    },
                    {
                        coursecode: 'NOR1267/NOR1268/NOR1269',
                        semester: 'yearspring',
                        place: 'online',
                    },
                ],
            },
            autumn: {
                courses: [
                    {
                        coursecode: 'MAT1151',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'NAT1007',
                        semester: 'autumn',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-NAT1007',
                        semester: 'lab_practice_autum',
                        place: 'live',
                    },
                    {
                        coursecode: 'HIS1010',
                        semester: 'autumn',
                        place: 'live',
                    },
                    {
                        coursecode: 'NOR1267/NOR1268/NOR1269',
                        semester: 'year',
                        place: 'live',
                    },
                ],
            },
        },
        sonans: {
            spring: {
                courses: [
                    {
                        coursecode: 'MAT1151',
                        semester: 'yearspring',
                        place: 'online',
                    },
                    {
                        coursecode: 'intro-MAT-online-p-p-y',
                        semester: 'spring',
                        place: 'online',
                    },
                    {
                        coursecode: 'NAT1007',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'HIS1011',
                        semester: 'spring',
                        place: 'online',
                    },
                    {
                        coursecode: 'NOR1267/NOR1268/NOR1269',
                        semester: 'yearspring',
                        place: 'online',
                    },
                ],
            },
            autumn: {
                courses: [
                    {
                        coursecode: 'MAT1151',
                        semester: 'year',
                        place: 'online',
                    },
                    {
                        coursecode: 'intro-MAT-online-p-p-y',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'NAT1018',
                        semester: 'spring',
                        place: 'online',
                    },
                    {
                        coursecode: 'HIS1011',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'NOR1267/NOR1268/NOR1269',
                        semester: 'year',
                        place: 'online',
                    },
                ],
            },
        },
    },
    'Medisinpakke - Utland': {
        sonpriosl: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3045',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3035',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3035',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3046/REA3047',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3036/REA3037',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3036/REA3037',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                ],
            },
        },
        campus: {
            autumn: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3045',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3035',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3035',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3046/REA3047',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3036/REA3037',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3036/REA3037',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                ],
            },
        },
        sonpriber: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3045',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3035',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3035',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3036/REA3037',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3036/REA3037',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                ],
            },
        },
        sonpridra: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3045',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3035',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3035',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3036/REA3037',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3036/REA3037',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                ],
            },
        },
        sonprifre: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3045',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3035',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3035',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3036/REA3037',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3036/REA3037',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                ],
            },
        },
        sonprikri: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3045',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3035',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3035',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3036/REA3037',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3036/REA3037',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                ],
            },
        },
        sonprista: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3045',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3035',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3035',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3036/REA3037',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3036/REA3037',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                ],
            },
        },
        sonpriton: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3045',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3035',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3035',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3036/REA3037',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3036/REA3037',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                ],
            },
        },
        sonpritro: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3045',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3035',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3035',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3046/REA3047',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3036/REA3037',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3036/REA3037',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                ],
            },
        },
        sonpritrom: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3045',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3035',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3035',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3036/REA3037',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3036/REA3037',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                ],
            },
        },
        sondig: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3045',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3035',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3035',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3036/REA3037',
                        semester: 'autumn',
                        place: 'online',
                    },
                ],
            },
            autumn: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'autumn',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3045',
                        semester: 'lab_practice_autum',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3035',
                        semester: 'autumn',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3035',
                        semester: 'lab_practice_autum',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3046/REA3047',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3036/REA3037',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3036/REA3037',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                ],
            },
        },
        sonans: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'spring',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3035',
                        semester: 'spring',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3036/REA3037',
                        semester: 'autumn',
                        place: 'online',
                    },
                ],
            },
            autumn: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3035',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'spring',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3036/REA3037',
                        semester: 'spring',
                        place: 'online',
                    },
                ],
            },
        },
    },
    'Medisinpakke - Norge': {
        sonpriosl: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3045',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3056',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3046/REA3047',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3038',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                ],
            },
        },
        campus: {
            autumn: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3045',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3056',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3046/REA3047',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3038',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                ],
            },
        },
        sonpriber: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3045',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3056',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3038',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                ],
            },
        },
        sonpridra: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3045',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3056',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3038',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                ],
            },
        },
        sonprifre: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3045',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3056',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3038',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                ],
            },
        },
        sonprikri: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3045',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3056',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3038',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                ],
            },
        },
        sonprista: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3045',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3056',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3038',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                ],
            },
        },
        sonpriton: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3045',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3056',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3038',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                ],
            },
        },
        sonpritro: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3045',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3056',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3046/REA3047',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3038',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                ],
            },
        },
        sonpritrom: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3045',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3056',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3038',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                ],
            },
        },
        sondig: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3045',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3056',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'autumn',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3038',
                        semester: 'lab_practice_autum',
                        place: 'live',
                    },
                ],
            },
            autumn: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'autumn',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3045',
                        semester: 'lab_practice_autum',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3056',
                        semester: 'autumn',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3046/REA3047',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3038',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                ],
            },
        },
        sonans: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'spring',
                        place: 'online',
                    },
                    {
                        coursecode: 'intro-MAT-online-r',
                        semester: 'spring',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3056',
                        semester: 'spring',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'autumn',
                        place: 'online',
                    },
                ],
            },
            autumn: {
                courses: [
                    {
                        coursecode: 'REA3045',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'intro-MAT-online-r',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3056',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3046/REA3047',
                        semester: 'spring',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'spring',
                        place: 'online',
                    },
                ],
            },
        },
    },
    Ingeniørpakke: {
        sonpriosl: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3056',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3058',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3038',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                ],
            },
        },
        campus: {
            autumn: {
                courses: [
                    {
                        coursecode: 'REA3056',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3058',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3038',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                ],
            },
        },
        sonpriber: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3056',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3058',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3038',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                ],
            },
        },
        sonpridra: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3056',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3058',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3038',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                ],
            },
        },
        sonprifre: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3056',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3058',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3038',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                ],
            },
        },
        sonprikri: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3056',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3058',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3038',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                ],
            },
        },
        sonprista: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3056',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3058',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3038',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                ],
            },
        },
        sonpriton: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3056',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3058',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3038',
                        semester: 'lab_practice_spring',
                        place: 'campus',
                    },
                ],
            },
        },
        sonpritro: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3056',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3058',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3038',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                ],
            },
        },
        sonpritrom: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3056',
                        semester: 'spring',
                        place: 'campus',
                    },
                    {
                        coursecode: 'REA3058',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'autumn',
                        place: 'campus',
                    },
                    {
                        coursecode: 'lab-REA3038',
                        semester: 'lab_practice_autum',
                        place: 'campus',
                    },
                ],
            },
        },
        sondig: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3056',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3058',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'autumn',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3038',
                        semester: 'lab_practice_autum',
                        place: 'live',
                    },
                ],
            },
            autumn: {
                courses: [
                    {
                        coursecode: 'REA3056',
                        semester: 'autumn',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3058',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'spring',
                        place: 'live',
                    },
                    {
                        coursecode: 'lab-REA3038',
                        semester: 'lab_practice_spring',
                        place: 'live',
                    },
                ],
            },
        },
        sonans: {
            spring: {
                courses: [
                    {
                        coursecode: 'REA3056',
                        semester: 'spring',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3058',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'intro-MAT-online-r',
                        semester: 'spring',
                        place: 'online',
                    },
                ],
            },
            autumn: {
                courses: [
                    {
                        coursecode: 'REA3056',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'intro-MAT-online-r',
                        semester: 'autumn',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3058',
                        semester: 'spring',
                        place: 'online',
                    },
                    {
                        coursecode: 'REA3038',
                        semester: 'spring',
                        place: 'online',
                    },
                ],
            },
        },
    },
};

export function getSemesterPackCourses(packName: string, location: string, semester: string) {
    const pack = packs?.[packName]?.[location]?.[semester]?.['courses'] || packs?.[packName]?.['campus']?.[semester]?.['courses'];
    return pack;
}