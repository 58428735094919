import { CourseVariants, StructuredCourse, selectedCourses } from '../../../../types';
import { Icon } from '../Icon/Icon';
import { useState, useEffect } from 'react';
import { classes } from '../../utils/helpers';
import { CheckIfFullyearCourse, FindOppositeSemester } from '../../../Helpers/Functions/CheckSemester';
import { getChosenSemVariants } from '../../../Helpers/CourseSearch/CourseSearchHelpers';
import FormatPrice from '../../../Helpers/Functions/FormatPrice';

type Props = {
  course: StructuredCourse;
  schoolType: string;
  selectedCampus: string;
  currentSemester: string;
  selectCourse: (course: StructuredCourse, type: string) => void;
  selCourseArr: selectedCourses[];
};

export function CourseLine({ course, schoolType, selectedCampus, currentSemester, selectCourse, selCourseArr }: Props) {
  const [selectedType, setSelectedType] = useState<string>('');
  const [selectedPrice, setSelectedPrice] = useState<number>(0);
  const [selectedOtherSem, setSelectedOtherSem] = useState<string>('');
  const [coursePrice, setCoursePrice] = useState<number>(0);
  const [showOnline, setShowOnline] = useState<boolean>(false);
  const [showLive, setShowLive] = useState<boolean>(false);
  const [showCampus, setShowCampus] = useState<boolean>(false);
  const [disableCampus, setDisableCampus] = useState<boolean>(false);

  useEffect(() => {
    let price = 0;
    const isFullyear: boolean = CheckIfFullyearCourse(course.courseCode);
    let mainCourse: CourseVariants[] = [];
    let thisSem = isFullyear ? 'year' : currentSemester;
    let otherSem = isFullyear ? 'year' : FindOppositeSemester(currentSemester);
    if (
      schoolType === 'online' ||
      (course.places.indexOf(selectedCampus) === -1 && course.places.indexOf('sonans') !== -1) ||
      (schoolType === 'kombi' && course.places.indexOf('sonans') !== -1)
    ) {
      mainCourse = findMainCourse('sonans', 'netbased');
      if (mainCourse.length > 0) {
        price = isFullyear ? mainCourse[0].priceNetFullyear : mainCourse[0].priceNetHalfyear;
      }
    } else if (schoolType === 'campus' || schoolType === 'live' || schoolType === 'kombi') {
      if (course.courseCode.includes('lab-')) {
        thisSem = thisSem === 'autumn' ? 'lab_practice_autum' : 'lab_practice_spring';
        otherSem = thisSem === 'lab_practice_autum' ? 'lab_practice_spring' : 'lab_practice_autum';
      } else if (course.courseCode.includes('intro-')) {
        thisSem = thisSem === 'autumn' ? 'introautumn' : 'introspring';
        otherSem = thisSem === 'introautumn' ? 'introspring' : 'introautumn';
        if (course.times.indexOf(thisSem) === -1) {
          thisSem = thisSem === 'introautumn' ? 'summer_school' : 'newyear_school';
        }
        if (course.times.indexOf(otherSem) === -1) {
          otherSem = otherSem === 'introautumn' ? 'summer_school' : 'newyear_school';
        }
      }

      let campus = selectedCampus;
      if (course.places.indexOf(campus) === -1) {
        campus = 'sondig';
      }

      mainCourse = findMainCourse(campus, thisSem);
      if (mainCourse.length === 0 || mainCourse[0].price === 0) {
        mainCourse = findMainCourse(campus, otherSem);
      }
      if (mainCourse.length === 0 || mainCourse[0].price === 0) {
        mainCourse = findMainCourse(campus, 'year');
      }
      if (mainCourse.length > 0) {
        price = mainCourse[0].price;
      }
    }

    
    setCoursePrice(price);
  }, [course, schoolType, selectedCampus, currentSemester]);

  useEffect(() => {
    if (schoolType === 'kombi') {
      setShowCampus(true);
      setShowLive(true);
      setShowOnline(true);
    } else if (schoolType === 'online') {
      setShowOnline(true);
    } else if (schoolType === 'live') {
      setShowLive(true);
      if (course.places.indexOf(selectedCampus) === -1) {
        setShowOnline(true);
      }
    } else if (schoolType === 'campus') {
      setShowCampus(true);
      if (course.places.indexOf(selectedCampus) === -1) {
        setShowOnline(true);
        setShowLive(true);
      }
    }
    const isPrevSem = JSON.parse(localStorage.getItem('prevSem') || 'false');
    if ((isPrevSem && currentSemester === 'spring') || course.places.indexOf(selectedCampus) === -1) {
      setDisableCampus(true);
    } else {
      setDisableCampus(false);
    }
  }, [course, schoolType, selectedCampus]);

  function findMainCourse(selectedCampus: string, time: string) {
    return course.variants.filter((c) => c.places.indexOf(selectedCampus) !== -1 && c.time === time);
  }

  useEffect(() => {
    let index = selCourseArr.findIndex((ca) => ca.courseCode === course.courseCode);
    if (index !== -1) {
      setSelectedType(selCourseArr[index].type);
      setSelectedPrice(selCourseArr[index].selectedPrice);
      const shownSemesters = getChosenSemVariants(currentSemester);
      if (shownSemesters.indexOf(selCourseArr[index].selectedSemester) === -1) {
        if (selCourseArr[index].selectedSemester === 'year' || selCourseArr[index].selectedSemester === 'yearspring') {
          if (selCourseArr[index].type === 'online') {
            setSelectedOtherSem(' - Helår');
          } else {
            setSelectedOtherSem(' - Kun helår');
          }
        } else {
          if (selCourseArr[index].type === 'online') {
            setSelectedOtherSem(` - ${currentSemester === 'autumn' ? 'Vår' : 'Høst'}`);
          } else {
            setSelectedOtherSem(` - Kun ${currentSemester === 'autumn' ? 'vår' : 'høst'}`);
          }
        }
      } else {
        setSelectedOtherSem('');
      }
    } else if (index === -1) {
      setSelectedType('');
      setSelectedPrice(0);
      setSelectedOtherSem('');
    }
  }, [selCourseArr]);

  useEffect(()=> {
    if(course.vocationalSubject) {
        setShowLive(true);
        setCoursePrice(course.variants[0].price);
    }
  }, [course])

  return (
    <div key={course.courseCode + '-line'} className='mb-2 gap-1 flex justify-evenly'>
      <div className='border rounded-lg shadow-md tablet-s:h-[50px] pl-1 justify-center tablet-s:items-center flex flex-col ms-2 tablet-s:flex-row w-full'>
        <div className='w-full text-left text-xs tablet-s:text-base'>
          <b>
            {course.courseTitle
              .replace('Matematikk', 'Matte')
              .replace('matematikk', 'matte')
              .replace('Engelsk 1', 'Engelsk 1 (valgfritt programfag)')
              .replace('Engelsk 2', 'Engelsk 2 (valgfritt programfag)')
              .replace('Engelsk VG1', 'Engelsk VG1 (obligatorisk fellesfag)')
              .replace('PB', '')
              .replace(', BUA Vg2', '')
              .replace(', BUA prakiskandidat', '')
              .replace(', HEA praksiskandidat', '')
              .replace(', HSF Vg1', '')
              .replace(', HES Vg2', '')
              .replace(', HSE Vg3', '')
              .replace(', HEA Vg2', '')
              }
          </b>
          {selectedOtherSem !== '' && selectedOtherSem}
        </div>
        {selectedPrice > 0 && (
          <div className='mr-4 text-left text-xs tablet-s:text-base'>{FormatPrice(selectedPrice)},-</div>
        )}
        {selectedPrice === 0 && (
          <div className='mr-4 text-left text-xs tablet-s:text-base'>{FormatPrice(coursePrice)},-</div>
        )}
      </div>
      <div className='flex gap-1'>
        {(showCampus && (
          <button
            onClick={() => selectCourse(course, selectedCampus)}
            disabled={disableCampus}
            className={classes(
              'border rounded-lg shadow-md flex justify-center items-center w-[50px] h-[50px]',
              selectedType === 'campus' ? 'bg-first-color border-2 border-first-color-outline' : '',
              !disableCampus && selectedType !== 'campus' ? 'bg-white' : '',
              disableCampus ? 'bg-bg-grey' : ''
            )}>
            <Icon
              className='w-8'
              iconColor={course.places.indexOf(selectedCampus) !== -1 ? '#000' : '#6d6d6d'}
              type='campus'
            />
          </button>
        )) ||
          (schoolType !== 'online' && schoolType !== 'live' && <div className='w-[50px] h-[50px]'></div>)}
        {(showLive && (
          <button
            onClick={() => selectCourse(course, 'sondig')}
            disabled={course.places.indexOf('sondig') === -1 ? true : false}
            className={classes(
              'border rounded-lg shadow-md flex justify-center items-center w-[50px] h-[50px]',
              selectedType === 'live' ? 'bg-second-color border-2 border-second-color-outline' : '',
              course.places.indexOf('sondig') !== -1 && selectedType !== 'live' ? 'bg-white' : '',
              course.places.indexOf('sondig') === -1 ? 'bg-bg-grey' : ''
            )}>
            <Icon className='w-8' iconColor={course.places.indexOf('sondig') !== -1 ? '#000' : '#6d6d6d'} type='live' />
          </button>
        )) ||
          (schoolType !== 'online' && <div className='w-[50px] h-[50px]'></div>)}
        {(showOnline && (
          <button
            onClick={() => selectCourse(course, 'sonans')}
            disabled={course.places.indexOf('sonans') === -1 ? true : false}
            className={classes(
              'border rounded-lg shadow-md flex justify-center items-center w-[50px] h-[50px]',
              selectedType === 'online' ? 'bg-third-color border-2 border-third-color-outline' : '',
              course.places.indexOf('sonans') !== -1 && selectedType !== 'online' ? 'bg-white' : '',
              course.places.indexOf('sonans') === -1 ? 'bg-bg-grey' : ''
            )}>
            <Icon
              className='w-8'
              iconColor={course.places.indexOf('sonans') !== -1 ? '#6d6d6d' : '#6d6d6d'}
              type='online'
            />
          </button>
        )) || <div className='w-[50px] h-[50px]'></div>}
      </div>
    </div>
  );
}
