import { useEffect, useState } from "react"
import { PackInformation } from "../../../Helpers/GetText/GetText"

type Props = {
    pack: string;
}

export function SonansPackPopup({pack}: Props) {
    const [packInfo, setPackInfo] = useState<string>('')
    const [packExtra, setPackExtra] = useState<string[]>([])
    const [showExtra, setShowExtra] = useState<boolean>(false)

    useEffect(() => {
        const packInfo = PackInformation(pack)
        const packExtra = packInfo.slice(1)
        setPackInfo(packInfo[0])
        setPackExtra(packExtra)
    }, [])

    return (
        <div className="flex flex-col justify-center gap-4 p-2">
            <p>{packInfo}</p>

            {packExtra.length > 0 && <p onClick={() => setShowExtra(!showExtra)} className="underline font-semibold hover:cursor-pointer">{showExtra ? 'Les mindre' : 'Les mer'}</p>}
            {showExtra && <ol className="list-disc text-left ml-8 mr-8">{packExtra.map((extra, i) => (
                <li key={`sonans-pack-extra-${i}`}>{extra}</li>
            ))}</ol>}
        </div>
    )
}