import { useEffect, useState } from "react";
import { IPackageCoverage, PackStructure } from "../../../../types"
import FormatPrice from "../../../Helpers/Functions/FormatPrice";

type Props = {
    pack: PackStructure;
}

export function PackLine({pack}: Props){
    const [title, setTitle] = useState<string>('');
    const [price, setPrice] = useState<number>(0);

    useEffect(() => {
        const title = pack.title;
        const packCover : IPackageCoverage = JSON.parse(localStorage.getItem('packCoverage') || '[]')

        const price = packCover.priceAddedCourses - packCover.savingsIfAdded

        setTitle(title);
        setPrice(price);
    }, [pack])

    return(
        <div className="w-full flex justify-between bg-primary-color-light p-2">
            <div className="w-full flex flex-col text-left">
                <p className="text-lg font-bold">{title}:</p>
            </div>
            <p className="w-full text-right font-semibold">{FormatPrice(price)},-</p>
        </div>
    )

}