import { configureStore } from "@reduxjs/toolkit";
import { packsReducer } from "../features/Reducers/packs";
import { coursesReducer } from "../features/Reducers/courses";
import { examsReducer } from "../features/Reducers/exams";
import { placesReducer } from "../features/Reducers/places";

export const store = configureStore({
    reducer: {
        packs: packsReducer,
        courses: coursesReducer,
        exams: examsReducer,
        places: placesReducer
    }
})