import axios from 'axios'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICourses, StructuredCourse, CourseVariants, AppState, AppDispatch } from '../../types'
import { GetStructuredCourses } from '../Helpers/StructureData/StructureData';

interface CoursesState {
    courses: ICourses[];
    structuredCourses: StructuredCourse[];
    isLoading: boolean;
    error: string;
}

const initialState: CoursesState = {
    courses: [],
    structuredCourses: [],
    isLoading: false,
    error: '',
}

const courses = createSlice({
    name: 'courses',
    initialState,
    reducers: {
        setCourses(state, action: PayloadAction<ICourses[]>) {
            const c = action.payload
            const structured = GetStructuredCourses(c);
            state.courses = c;
            state.structuredCourses = structured;
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setError(state, action: PayloadAction<string>) {
            state.error = action.payload;
        }
    }
})

export const coursesReducer = courses.reducer;

export const {
    setCourses,
    setIsLoading,
    setError,
} = courses.actions;

export function fetchCourses() {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        try {
            dispatch(setIsLoading(true));
            const { data } = await axios.get(process.env.REACT_APP_WEB_API_URL + 'subjects')
            dispatch(setCourses(data));
        } catch (error: any) {
            setError(error.message);
        } finally {
            dispatch(setIsLoading(false))
        }
    }
}