import { GetAvailableSemesters } from '../Functions/GetAvailableSemesters';
import { CourseVariants, IconTypes, PackStructureCourse, selectedCourses, StructuredCourse } from '../../../types';
import { findCourseCode } from '../PackageCoursePicker/PackageCoursePicker';

export function yearTime(firstSemester: boolean) {
  const halfyearTime = GetAvailableSemesters()[0].firstSemester;
  var secondTime = 'year';
  var firstTime = 'yearspring';
  if (halfyearTime == 'autumn') {
    firstTime = 'year';
    secondTime = 'yearspring';
  }
  if (!firstSemester) {
    return secondTime;
  }
  return firstTime;
}
export function introTime(firstSemester: boolean) {
  const halfyearTime = GetAvailableSemesters()[0].firstSemester;
  var secondTime = 'introspring';
  var firstTime = 'introautumn';
  if (halfyearTime == 'autumn') {
    firstTime = 'introspring';
    secondTime = 'introautumn';
  }
  if (!firstSemester) {
    return secondTime;
  }
  return firstTime;
}

export function halfYearTime(firstSemester: boolean) {
  const firstTime = GetAvailableSemesters()[0].firstSemester;
  var secondTime = 'autumn';
  if (firstTime == 'autumn') {
    secondTime = 'spring';
  }
  if (!firstSemester) {
    return secondTime;
  }
  return firstTime;
}
export function labTime(firstSemester: boolean) {
  const firstTime = GetAvailableSemesters()[0].firstSemester;
  var secondTime = 'autumn';
  if (firstTime == 'autumn') {
    secondTime = 'spring';
  }

  if (!firstSemester) {
    return timeToLabTime(secondTime);
  }
  return timeToLabTime(firstTime);
}

export function isOffered(course: selectedCourses, time: string, agreementAlias: string) {
  // Check if time is included in available times
  const variants = course.variants;

  const useLive = course.type === 'live';
  const useOnline = course.type === 'online';
  const useOfferedHere = course.type === 'campus';

  const times = variants
    .filter((variant) => {
      if (useLive) {
        return variant.places.indexOf('sondig') !== -1;
      } else if (useOfferedHere) {
        return variant.places.indexOf(agreementAlias) !== -1;
      } else if (useOnline) {
        return variant.places.indexOf('sonans') !== -1;
      } else {
        return false;
      }
    })
    .map((variant) => {
      return variant.time;
    });
  return times.indexOf(time) !== -1;
}

export function isLabCourse(course: selectedCourses) {
  const courseCode = course.courseCode;
  return courseCode.indexOf('lab') === 0;
}

export function timeToLabTime(time: string) {
  if (time === 'spring') {
    return 'lab_practice_spring';
  }
  if (time === 'autumn') {
    return 'lab_practice_autum';
  }
  return 'lab_practice_year';
}

function translateVariantToSelectedCourse(
  placement: IconTypes,
  time: string,
  alternativeSemester: string,
  structuredCourse: StructuredCourse,
  variant: CourseVariants
) {
  let selSemester = time;
  let selTime = variant.time;
  let price = variant.price;

  if (time === 'netbased') {
    selSemester = alternativeSemester;
    if (alternativeSemester === 'year' || alternativeSemester === 'yearspring') {
      price = variant.priceNetFullyear;
    } else {
      price = variant.priceNetHalfyear;
    }
  }

  let foundCourse: selectedCourses = {
    courseCode: structuredCourse.courseCode,
    type: placement,
    course: structuredCourse,
    selectedSemester: time === 'netbased' ? alternativeSemester : time,
    selectedCourse: variant,
    labCourses: structuredCourse.labCourses,
    variants: structuredCourse.variants,
    selectedTime: time,
    upgradeCourses: structuredCourse.upgradeCourses,
    selectedPrice: price,
    upgradeTimes: structuredCourse.upgradePlaces,
    packCourse: false,
    pack: '',
    packTitle: '',
  };

  return foundCourse;
}

// Translate package course to our best estimated course
export function isOfferedStrucutredCourse(
  course: PackStructureCourse,
  placement: IconTypes,
  courses: StructuredCourse[],
  time: string,
  alternativeSemester: string,
  agreementAlias: string
) {
  let useCourseCode = findCourseCode(course, courses);
  let currentCourse = courses.filter((c) => c.courseCode === useCourseCode);

  const useLive = placement === 'live';
  const useOnline = placement === 'online';
  const useOfferedHere = placement === 'campus';

  if (currentCourse.length > 0) {
    const courseItem = currentCourse[0];
    const variants = currentCourse[0].variants;
    let aliasToUse = agreementAlias;
    if (useLive) {
      aliasToUse = 'sondig';
    } else if (useOnline) {
      aliasToUse = 'sonans';
    }
    const relevantVariants = variants.filter((v) => v.places.includes(aliasToUse));
    for (const otherVariant of relevantVariants) {
      // Renamed to otherVariant
      if (courseItem.courseCode === useCourseCode && otherVariant.time === time) {
        const isLive = useLive && otherVariant.places.includes('sondig');
        const isOnline = useOnline && otherVariant.places.includes('sonans');
        const isOfferedHere = useOfferedHere && otherVariant.places.includes(agreementAlias) && placement === 'campus';

        if (isLive || isOnline || isOfferedHere) {
          const selCourse = translateVariantToSelectedCourse(
            placement,
            time,
            alternativeSemester,
            courseItem,
            otherVariant
          );
          return selCourse;
        }
      }
    }
  }
  return undefined;
}
