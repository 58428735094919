import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState, PackStructure } from '../../../../types';
import { PageTitle, SemesterCourseContainer } from '../../../UI'
import { useEffect } from "react";
import { fetchCourses } from '../../../Reducers/courses';

interface Props {
    packs: PackStructure[]
}

export function Course({packs}: Props) {
    const dispatch = useDispatch<AppDispatch>();
    function getCourses() {
        dispatch(fetchCourses());
    }
    useEffect(() => getCourses(), []);
    const {courses, structuredCourses} = useSelector((state: AppState) => state.courses);

    return (
        <>
            <PageTitle title='Sett opp din plan' />
            <SemesterCourseContainer coursePacks={packs} courses={courses} structuredCourses={structuredCourses} />
        </>
    )
}