import { IconTypes, PackStructure, PackStructureCourse, StructuredCourse, selectedCourses } from "../../../types";
import { getSemesterPackCourses } from "../GetText/libraries/GetPacks";
import { findCourseCode, findOfferedPackageCourse } from "../PackageCoursePicker/PackageCoursePicker";
import { FindOppositeSemester } from "./CheckSemester";
import { getFirstSem } from "./GetAvailableSemesters";

export function CheckIfPack(packs: PackStructure[], schoolType: string, allCoursesStructured: StructuredCourse[], selectedCourses: selectedCourses[]) {
    const selectedCourseCodes = selectedCourses.map((sc) => sc.courseCode);
    const placement = schoolType as IconTypes;

    for (const rp of packs) {
        const pCourses = rp.courses || [] as PackStructureCourse[];
        const packTitle = rp.title.replace(' online', '').replace(' live', '');
        let schoolAlias = localStorage.getItem('schoolAlias') || 'sonans';
        if (schoolType === 'online') {
            schoolAlias = 'sonans';
        } else if (schoolType === 'live') {
            schoolAlias = 'sondig';
        }
        const isPrevSem = JSON.parse(localStorage.getItem('prevSem') || 'false');
        let firstSem = getFirstSem();
        if (isPrevSem) {
            firstSem = FindOppositeSemester(firstSem);
        }
        const getPackCourses = getSemesterPackCourses(packTitle, schoolAlias, firstSem);

        let foundCourses: selectedCourses[] = [];
        if (pCourses) {
            for (const pc of pCourses) {
                const courseCode = findCourseCode(pc, allCoursesStructured);
                const getPackCourse = getPackCourses?.filter((c) => c.coursecode === courseCode);
                let place = schoolType;
                let selPackCourse = { ...pc };
                if (getPackCourse?.length > 0) {
                    place = getPackCourse[0].place;
                    if (place === 'online') {
                        selPackCourse.alternative_type = getPackCourse[0].semester;
                        selPackCourse.semester_length_alias = 'netbased';
                    } else {
                        selPackCourse.semester_length_alias = getPackCourse[0].semester;
                    }
                }
                const course = findOfferedPackageCourse(selPackCourse, placement, allCoursesStructured, schoolAlias, pCourses);
                if (course) {
                    foundCourses.push(course);
                }
            }
        }

        const labCourses = foundCourses.filter((l) => l.courseCode.includes('lab'));
        const labToRemove = labCourses.filter((l) => {
            const cc = l.courseCode.replace('lab-', '');
            const mainCourse = foundCourses.filter((fc) => fc.courseCode === cc);
            if (mainCourse[0].type === 'online') {
                return l;
            }
        });

        let foundCourseCodes: string[] = [];
        foundCourses.forEach((fc) => {
            if (labToRemove.indexOf(fc) === -1) {
                foundCourseCodes.push(fc.courseCode);
            }
        });

        if (foundCourseCodes.every(cc => selectedCourseCodes.includes(cc)) && packTitle !== 'Sonanspakken') {
            return packTitle;
        }
    }
    return '';
}