import { FindOppositeSemester } from "./CheckSemester";

export function GetAvailableSemesters() {
    const isPrevSem = JSON.parse(localStorage.getItem('prevSem') || 'false')

    if(isPrevSem) {
      const semesters = GetPreviousSemesters();
      return semesters;
    } else {
      const semesters = GetCurrentSemesters();
      return semesters;
    }
  }

  export function GetCurrentSemesters() {
    const dateToday = new Date();
    let year = dateToday.getFullYear();
    let firstSemester = getFirstSem();
    year = getFirstSemYear(firstSemester);
    const semesters = [
      { title: year.toString(), semesterAmount: 2, firstSemester: firstSemester, year: year }
    ];
    return semesters;
  }

  export function GetPreviousSemesters() {
    const dateToday = new Date();
    let year = dateToday.getFullYear();
    const currentFirstSem = getFirstSem();
    const previousFirstSem = FindOppositeSemester(currentFirstSem);
    if (currentFirstSem === 'spring') {
      year = year - 1;
    }
    const semesters = [
      { title: year.toString(), semesterAmount: 2, firstSemester: previousFirstSem, year: year}
    ];
    return semesters;
  }

  function getSpringEnd(year: number) {
    // Constructing date objects using supported date string format
    const semesterEnd = new Date(year, 1, 11)
    return semesterEnd
  }

  function getAutumnEnd(year: number) {
    // Constructing date objects using supported date string format
    const semesterEnd = new Date(year, 8, 19)
    return semesterEnd
  }

  export function getFirstSem() {
    const dateToday = new Date();
    const year = dateToday.getFullYear();
    const autumnEnd = getAutumnEnd(year);
    const springEnd = getSpringEnd(year);
    const nextSpringEnd = getSpringEnd(year + 1);

    let firstSem = 'spring';
    if(dateToday > springEnd && dateToday < autumnEnd) {
      firstSem = 'autumn';
    } else if ((dateToday > autumnEnd && dateToday < nextSpringEnd) || (dateToday < springEnd)) {
      firstSem = 'spring';
    }

    return firstSem
  }

  function getFirstSemYear(semester: string) {
    const dateToday = new Date();
    let year = dateToday.getFullYear();
    const springEnd = getSpringEnd(year);
    const nextSpringEnd = getSpringEnd(year + 1);

    if(semester === 'spring' && dateToday < nextSpringEnd && dateToday > springEnd) {
      year += 1
    }

    return year
  }