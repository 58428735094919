export function ShortPackNames(title: string) {
    const packname = title.replace(' online', '').replace(' live', '')
    if(packname === 'Påbygging til generell studiekompetanse uten fagbrev' || packname === 'Påbygging til generell studiekompetanse') {
        return 'Påbygg uten fagbrev'
    } else if(packname === 'Påbygging til generell studiekompetanse med fagbrev' || packname === 'Påbygging med fagbrev') {
        return 'Påbygg med fagbrev'
    } else if(packname === 'Realfagskurs til ingeniørutdanning - helår') {
        return 'Ingeniørpakke'
    } else if(packname === 'Medisinpakke') {
        return 'Medisinpakke - Norge'
    } else if(packname === 'Medisinpakke utland') {
        return 'Medisinpakke - Utland'
    }

    return packname
}