import { useEffect, useState } from "react";
import { StructuredCourse, selectedCourses } from "../../../../types";
import { classes } from "../../utils/helpers";
import { Icon } from "../Icon/Icon";
import { getChosenSemVariants } from "../../../Helpers/CourseSearch/CourseSearchHelpers";
import { ShortenCourseName } from "../../../Helpers/Functions/ShortenCourseName";



type IconTypes = 'check' | 'arrow-thin-left' | 'arrow-thin-right' | 'arrow-full-left' | 'arrow-full-right' | 'close-icon' | 'help' | 'logo' | 'oral-exam' | 'oral-lab-exam' | 'plus-bubble' | 'save' | 'symbol' | 'written-exam' | 'campus' | 'online' | 'live' | 'clock' | 'sun' | 'moon';

interface Props {
    selCourseArr: selectedCourses[];
    selectedSchool: string;
    currentSem: string;
    selectCourse: (course: StructuredCourse, type: string, remove: boolean) => void;
}

export function SelectedCourses({ selCourseArr, selectedSchool, currentSem, selectCourse }: Props) {
    const [campusCourses, setCampusCourses] = useState<selectedCourses[]>([]);
    const [schoolALias, setSchoolAlias] = useState<string>('');
    const [liveCourses, setLiveCourses] = useState<selectedCourses[]>([]);
    const [onlineCourses, setOnlineCourses] = useState<selectedCourses[]>([]);
    const [showCampus, setShowCampus] = useState<boolean>(true)
    const [showLive, setShowLive] = useState<boolean>(true)
    const [showOnline, setShowOnline] = useState<boolean>(true)

    useEffect(() => {
        let campusArr: selectedCourses[] = [];
        let liveArr: selectedCourses[] = [];
        let onlineArr: selectedCourses[] = [];

        const selections = selCourseArr

        selections.map((sc) => {
            if (sc.type === 'campus') {
                campusArr.push(sc)
            } else if (sc.type === 'live') {
                liveArr.push(sc)
            } else if (sc.type === 'online') {
                onlineArr.push(sc)
            }
        })

        setCampusCourses(campusArr)
        setLiveCourses(liveArr)
        setOnlineCourses(onlineArr)

    }, [selCourseArr])

    useEffect(() => {
        let schoolType: string = localStorage.getItem('schoolType') || ''
        if (schoolType === 'live') {
            setShowCampus(false)
        } else if (schoolType === 'online') {
            setShowCampus(false)
            setShowLive(false)
        }
        let alias: string = localStorage.getItem('schoolAlias') || ''
        setSchoolAlias(alias);
    }, [])

    function translateSelectedSem(sem: string) {
        const autumn = getChosenSemVariants('autumn')
        const spring = getChosenSemVariants('spring')
        if (autumn.indexOf(sem) !== -1) {
            return 'høst'
        } else if (spring.indexOf(sem) !== -1) {
            return 'vår'
        } else if (sem === 'year' || sem === 'yearspring') {
            return 'helår'
        }
    }

    let listWidth = 'w-1/3'
    if (!showCampus && !showLive && showOnline) {
        listWidth = 'w-full'
    } else if (!showCampus && showLive && showOnline) {
        listWidth = 'w-1/2'
    }

    return (
        <div className="flex w-full justify-around gap-1">
            {showCampus && <div className={`flex flex-col ${listWidth}`}>
                <div className="border-4 border-first-color-outline pt-2 pb-2 bg-first-color flex gap-2 justify-center items-center text-xs tablet-m:text-base">
                    <Icon className="w-8" type="campus" />
                    <p className="font-semibold underline">{selectedSchool}</p>
                </div>
                <ul className="h-20 tablet-s:h-20 laptop-m:h-40 overflow-x-hidden text-sm tablet-s:text-base">
                    {campusCourses.map((c) => (
                        <li className="flex justify-between text-sm border-b-2 p-1 relative" key={c.courseCode + '-selected-campus'}>
                            <div className="flex flex-col text-left w-2/4">
                                <p className="font-semibold text-xs tablet-s:text-base">{ShortenCourseName(c.course.courseTitle)}</p>
                                <p>{translateSelectedSem(c.selectedSemester)}</p>
                            </div>
                            <div className="hover:cursor-pointer justify-center flex flex-col absolute top-2 right-2" onClick={() => selectCourse(c.course, schoolALias, true)}>
                                <Icon className="w-4" type="close-icon" />
                            </div>
                        </li>
                    ))}
                </ul>
            </div>}
            {showLive && <div className={`flex flex-col ${listWidth}`}>
                <div className="border-4 border-second-color-outline pt-2 pb-2 bg-second-color flex gap-2 justify-center items-center text-xs tablet-m:text-base">
                    <Icon className="w-8" type="live" />
                    <p className="font-semibold underline">Live</p>
                </div>
                <ul className="h-20 tablet-s:h-20 laptop-m:h-40 overflow-x-hidden text-sm tablet-s:text-base">
                    {liveCourses.map((l) => (
                        <li className="flex justify-between text-sm border-b-2 p-1 relative" key={l.courseCode + '-selected-live'}>
                            <div className="flex flex-col text-left w-2/4">
                                <p className="font-semibold text-xs tablet-s:text-base">{ShortenCourseName(l.course.courseTitle)}</p>
                                <p>{translateSelectedSem(l.selectedSemester)}</p>
                            </div>
                            <div className="hover:cursor-pointer justify-center flex flex-col absolute top-2 right-2" onClick={() => selectCourse(l.course, 'sondig', true)}>
                                <Icon className="w-4" type="close-icon" />
                            </div>
                        </li>
                    ))}
                </ul>
            </div>}
            {showOnline && <div className={`flex flex-col ${listWidth}`}>
                <div className="border-4 border-third-color-outline pt-2 pb-2 bg-third-color flex gap-2 justify-center items-center text-xs tablet-m:text-base">
                    <Icon className="w-8" type="online" />
                    <p className="font-semibold underline">Online</p>
                </div>
                <ul className="h-20 tablet-s:h-20 laptop-m:h-40 overflow-x-hidden text-sm tablet-s:text-base">
                    {onlineCourses.map((o) => (
                        <li className="flex justify-between text-sm border-b-2 p-1 relative" key={o.courseCode + '-selected-online'}>
                            <div className="flex flex-col text-left w-2/4">
                                <p className="font-semibold text-xs tablet-s:text-base">{ShortenCourseName(o.course.courseTitle)}</p>
                                <p>{translateSelectedSem(o.selectedSemester)}</p>
                            </div>
                            <div className="hover:cursor-pointer justify-center flex flex-col absolute top-2 right-2" onClick={() => selectCourse(o.course, 'sonans', true)}>
                                <Icon className="w-4" type="close-icon" />
                            </div>
                        </li>
                    ))}
                </ul>
            </div>}
        </div>
    )
}