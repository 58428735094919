import React, { useEffect, useState } from 'react'
import { PageTitle, Button, Icon } from '../../../UI'
import Modal from '../../../UI/components/Modal/Modal';
import { useNavigate } from 'react-router-dom';

type placeType = { agreement_id: string, alias: string, name: string }[]

type Props = {
    places: { agreement_id: string, alias: string, name: string }[];
    setNextArrow: () => void;
}

export function Place({ places, setNextArrow }: Props) {
    const [showCampus, setShowCampus] = useState(false);
    const [showKombi, setShowKombi] = useState(false);
    const [showCampusText, setShowCampusText] = useState(false);
    const [showOnlineText, setShowOnlineText] = useState(false);
    const [showLiveText, setShowLiveText] = useState(false);
    const [showKombiText, setShowKombiText] = useState(false);
    const [placesArr, setPlacesArr] = useState<placeType>([])
    const [campusSelection, setCampusSelection] = useState<string>('Velg by')
    const [kombiCampusSelection, setKombiCampusSelection] = useState<string>('Kombiner')
    const [typeSelection, setTypeSelection] = useState<string>('')
    const [aliasSelection, setAliasSelection] = useState<string>('')
    const [warnSwitch, setWarnSwitch] = useState<boolean>(false)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [switchSchool, setSwitchSchool] = useState<string>('')
    const [showNext, setShowNext] = useState<boolean>(false)

    const navigate = useNavigate()

    function handleCloseModal() {
        setModalOpen(false)
    }

    function handleOpenModal() {
        setModalOpen(true)
    }


    useEffect(() => {
        setPlacesArr(places)
    }, [places])

    function selectSchool(school: string) {
        handleCloseModal()
        setTypeSelection(school);
        setWarnSwitch(false)
        localStorage.setItem('selectedCourses', '')
        localStorage.setItem('selectedPack', '')
        setKombiCampusSelection('Kombiner')
        setCampusSelection('Velg by')
        if (school === 'campus') {
            setShowCampus(!showCampus)
            setShowKombi(false)
        } else if (school === 'kombi') {
            setShowCampus(false)
            setShowKombi(!showKombi)
        } else if (school === 'online') {
            setAliasSelection('sonans')
            localStorage.setItem('schoolType', school)
            localStorage.setItem('schoolAlias', 'sonans')
            localStorage.setItem('schoolName', 'Online')
            navigate('/fag')
        } else if (school === 'live') {
            setAliasSelection('sondig')
            localStorage.setItem('schoolType', school)
            localStorage.setItem('schoolAlias', 'sondig')
            localStorage.setItem('schoolName', 'Live')
            navigate('/fag')
        }
    }

    function checkIfChosenSchool(school: string) {
        if (warnSwitch) {
            setSwitchSchool(school)
            handleOpenModal()
        } else {
            selectSchool(school)
        }
    }

    useEffect(() => {
        const school = localStorage.getItem('schoolType') || ''
        const schoolAlias = localStorage.getItem('schoolAlias') || ''
        const schoolName = localStorage.getItem('schoolName') || ''
        if (school !== '' && schoolAlias !== '' && schoolName !== '') {
            setNextArrow();
            setShowNext(true)
        } else {
            setShowNext(false)
        }
        setAliasSelection(schoolAlias)
        setTypeSelection(school)
        if (school === 'campus' && schoolName !== '') {
            setCampusSelection(schoolName)
        } else if (school === 'kombi' && schoolName !== '') {
            setKombiCampusSelection(schoolName)
        }
    }, [])

    useEffect(() => {
        const courseList = JSON.parse(localStorage.getItem('selectedCourses') || '[]')
        if (campusSelection !== '' && courseList.length > 0) {
            setWarnSwitch(true)
        }
    }, [campusSelection])

    function showText(school: string) {
        if (school === 'campus') {
            setShowCampusText(!showCampusText)
        } else if (school === 'live') {
            setShowLiveText(!showLiveText)
        } else if (school === 'online') {
            setShowOnlineText(!showOnlineText)
        } else if (school === 'kombi') {
            setShowKombiText(!showKombiText)
        }
    }

    function selectPlace(type: string, campus: string, alias: string) {
        setAliasSelection(alias)
        localStorage.setItem('schoolType', type)
        localStorage.setItem('schoolAlias', alias)
        localStorage.setItem('schoolName', campus)
        if (type === 'campus') {
            setCampusSelection(campus);
            setKombiCampusSelection('Kombiner');
            setShowCampus(false);
        } else if (type === 'kombi') {
            setKombiCampusSelection(campus);
            setCampusSelection('Velg by');
            setShowKombi(false);
        }
        navigate('/fag')
    }

    return (
        <>
            <PageTitle title='Velg undervisningsformen som passer for deg' />

            <div className='flex justify-between items-start gap-4 tablet-s:flex-row flex-col align-top'>

                <div className={`border-4 ${typeSelection === 'online' ? 'border-third-color' : ''}  flex justify-start tablet-s:flex-col w-full flex-row tablet-s:min-h-[320px] desktop:min-h-[420px]`}>

                    <div onClick={() => checkIfChosenSchool('online')} className='bg-third-color w-1/3 tablet-s:w-full p-4 laptop-m:h-48 justify-center items-center flex flex-col relative'>
                        {/* <div className='bg-white absolute top-0 left-0 ml-1 mt-1 font-bold pl-2 pr-2 text-sm tablet-s:text-lg'>Kampanje!</div> */}
                        <Icon className='mt-4 tablet-s:mt-0 w-16 tablet-s:w-28' type="online" />
                        <p className='tablet-s:text-xl mobile-s:text-md font-bold'>Online</p>
                        <p><u>Nett</u>studier</p>
                    </div>

                    <div className='flex flex-col mx-auto'>
                        <div className='p-2 tablet-s:p-4 flex flex-col justify-between gap-2 tablet-s:gap-4'>
                            <p className='font-semibold'>Når du vil, <br></br> med lærerstøtte.</p>
                            <Button onClick={() => checkIfChosenSchool('online')} mode={typeSelection === 'online' ? 'light' : 'green'}>{typeSelection === 'online' ? 'Online' : 'Velg Online'}</Button>
                            <div className='pl-4 pr-4 flex justify-center'>
                                {!showOnlineText &&
                                    <ol className="text-left w-66">
                                        <li>Studer i eget tempo</li>
                                    </ol>
                                }
                                {showOnlineText &&
                                    <ol className='list-disc text-left'>
                                        <li>Studer i eget tempo</li>
                                        <li>Mange selvtester og valgfrie innsendingsoppgaver</li>
                                        <li>Videoundervisning i kombinasjon med fagteori</li>
                                        <li>Pedagogisk læreplattform; full oversikt over pensum</li>
                                        <li>Faglærer hjelper deg</li>
                                    </ol>
                                }
                            </div>
                        </div>
                        <button className='self-center' onClick={() => showText('online')}>
                            <Icon className={`w-2 ${showOnlineText ? 'rotate-[90deg]' : 'rotate-[270deg]'}`} type="arrow-full-left" />
                        </button>
                    </div>

                </div>

                <div className={`border-4 ${typeSelection === 'campus' ? 'border-first-color' : ''} flex justify-start tablet-s:flex-col w-full flex-row tablet-s:min-h-[320px] desktop:min-h-[420px]`}>

                    <div onClick={() => checkIfChosenSchool('campus')} className='bg-first-color w-1/3 tablet-s:w-full p-4 laptop-m:h-48 justify-center items-center flex flex-col'>
                        <Icon className='w-16 tablet-s:w-28' type="campus" />
                        <p className='tablet-s:text-xl mobile-s:text-md font-bold'>Campus</p>
                        <p><u>Fysisk</u> undervisning</p>
                    </div>

                    <div className='flex flex-col mx-auto'>

                        <div className='p-2 tablet-s:p-4 flex flex-col justify-between gap-2 tablet-s:gap-4'>
                            <p className='font-semibold break-normal'>Oppmøte i klasserom <br></br> til faste tider.</p>
                            <div className='relative'>
                                <Button className=' w-full flex justify-center gap-2 items-baseline' mode={typeSelection === 'campus' ? 'light' : 'yellow'} onClick={() => checkIfChosenSchool('campus')}>{campusSelection} <Icon className={`w-2 ${showCampus ? 'rotate-[90deg]' : 'rotate-[270deg]'}`} type="arrow-full-left" /></Button>
                                <div className={`border-4 border-first-color absolute w-full z-50 ${showCampus ? '' : 'hidden'}`}>
                                    <ol className='text-left text-base tablet-s:text-lg'>
                                        {placesArr.map((place) => [
                                            <li onClick={() => selectPlace('campus', place.name, place.alias)} className='bg-white even:bg-bg-light-grey p-2 hover:font-semibold hover:cursor-pointer' key={place.alias}>{place.name}</li>
                                        ])}
                                    </ol>
                                </div>
                            </div>
                            <div className='pl-8 pr-8 flex justify-center'>
                                {!showCampusText &&
                                    <ol className="text-left w-66">
                                        <li>Campuser i 9 byer</li>
                                    </ol>
                                }
                                {showCampusText &&
                                    <ol className='list-disc text-left'>
                                        <li>Campuser i 9 byer</li>
                                        <li>Strukturert studiehverdag</li>
                                        <li>Grupperom og leseplasser</li>
                                        <li>Sosialt studentmiljø</li>
                                        <li>Arrangementer</li>
                                        <li>Leksehjelp</li>
                                    </ol>
                                }
                            </div>
                        </div>

                        <button className='self-center' onClick={() => showText('campus')}>
                            <Icon className={`w-2 ${showCampusText ? 'rotate-[90deg]' : 'rotate-[270deg]'}`} type="arrow-full-left" />
                        </button>

                    </div>

                </div>


                <div className={`border-4 ${typeSelection === 'live' ? 'border-second-color' : ''} flex justify-start tablet-s:flex-col w-full flex-row tablet-s:min-h-[320px] desktop:min-h-[420px]`}>

                    <div onClick={() => checkIfChosenSchool('live')} className='bg-second-color w-1/3 tablet-s:w-full p-4 laptop-m:h-48 justify-center items-center flex flex-col'>
                        <Icon className='w-16 tablet-s:w-28' type="live" />
                        <p className='tablet-s:text-xl mobile-s:text-md font-bold'>Live</p>
                        <p><u>Digital</u> undervisning</p>
                    </div>

                    <div className='flex flex-col mx-auto'>
                        <div className='p-2 tablet-s:p-4 flex flex-col ustify-between gap-2 tablet-s:gap-4'>
                            <p className='font-semibold break-normal'>Livestreamet <br></br> til faste tider.</p>
                            <Button onClick={() => checkIfChosenSchool('live')} mode={typeSelection === 'live' ? 'light' : 'pink'}>{typeSelection === 'live' ? 'Live' : 'Velg Live'}</Button>
                            <div className='pl-4 pr-4 flex justify-center'>
                                {!showLiveText &&
                                    <ol className="text-left w-66">
                                        <li>Fleksibilitet og struktur</li>
                                    </ol>
                                }
                                {showLiveText &&
                                    <ol className='list-disc text-left'>
                                        <li>Fleksibilitet og struktur</li>
                                        <li>Direkte videoundervisning med fast timeplan</li>
                                        <li>Repeter med opptak</li>
                                        <li>Studer fra hvor du vil</li>
                                        <li>Mulighet for aktiv deltakelse i undervisningen</li>
                                        <li>Skreddersydd for et godt digitalt læringsmiljø</li>
                                    </ol>
                                }
                            </div>
                        </div>
                        <button className='self-center' onClick={() => showText('live')}>
                            <Icon className={`w-2 ${showLiveText ? 'rotate-[90deg]' : 'rotate-[270deg]'}`} type="arrow-full-left" />
                        </button>
                    </div>

                </div>




                <div className={`border-4 ${typeSelection === 'kombi' ? 'border-black' : ''} flex justify-start tablet-s:flex-col w-full flex-row tablet-s:min-h-[320px] desktop:min-h-[420px]`}>

                    <div onClick={() => checkIfChosenSchool('kombi')} className='bg-black text-white w-1/3 tablet-s:w-full p-4 laptop-m:h-48 justify-center items-center flex flex-col'>
                        <div className='flex justify-between gap-2 h-16 w-28 tablet-s:w-full tablet-s:h-28'>
                            <Icon type="campus" className='w-14 laptop-m:w-[50%] self-baseline' iconColor='#ffffff' />
                            <Icon type="live" className='w-14 laptop-m:w-[50%] self-end' iconColor='#ffffff' />
                            <Icon type="online" className='w-14 laptop-m:w-[50%] self-baseline' iconColor='#ffffff' />
                        </div>
                        <p className='tablet-s:text-xl text-md font-bold'>Kombiner</p>
                        <p><u>Skreddersy</u> din plan</p>
                    </div>


                    <div className='flex flex-col mx-auto'>
                        <div className='p-2 tablet-s:p-4 flex flex-col justify-between gap-2 tablet-s:gap-4'>
                            <p className='font-semibold'>Kombiner campus, live <br></br> og online.</p>
                            <div className='relative'>
                                <Button onClick={() => checkIfChosenSchool('kombi')} className='flex w-full justify-center gap-2 items-baseline' mode={typeSelection === 'kombi' ? 'light' : 'dark'}>{kombiCampusSelection} <Icon iconColor={typeSelection === 'kombi' ? '#000' : '#fff'} className={`w-2 ${showKombi ? 'rotate-[90deg]' : 'rotate-[270deg]'}`} type="arrow-full-left" /></Button>
                                <div className={`border-4 border-first-color absolute w-full z-50 ${showKombi ? '' : 'hidden'}`}>
                                    <ol className='text-left text-base tablet-s:text-lg'>
                                        {placesArr.map((place) => [
                                            <li onClick={() => selectPlace('kombi', place.name, place.alias)} className='bg-white even:bg-bg-light-grey p-2 hover:font-semibold hover:cursor-pointer' key={place.alias}>{place.name}</li>
                                        ])}
                                    </ol>
                                </div>
                            </div>
                            <div className='pl-4 pr-4 flex justify-center'>
                                {!showKombiText &&
                                    <ol className="text-left w-66">
                                        <li>Tilpass ditt løp</li>
                                    </ol>
                                }
                                {showKombiText &&
                                    <ol className='list-disc text-left'>
                                        <li>Tilpass ditt løp</li>
                                        <li>Alle undervisningsformer har:</li>
                                        <ol className='list-decimal'>
                                            <li>Engasjerende lærere</li>
                                            <li>Undervisning spisset mot eksamen</li>
                                            <li>Mulighet for kollokvie- og gruppearbeid</li>
                                            <li>Valgfrie innsendingsoppgaver</li>
                                            <li>Prøveeksamen</li>
                                        </ol>
                                    </ol>
                                }
                            </div>
                        </div>
                        <button className='self-center' onClick={() => showText('kombi')}>
                            <Icon className={`w-2 ${showKombiText ? 'rotate-[90deg]' : 'rotate-[270deg]'}`} type="arrow-full-left" />
                        </button>
                    </div>


                </div>
            </div>

            <Modal title='Er du sikker på at du vil bytte undervisningsform?' isOpen={modalOpen} onClose={() => handleCloseModal()}>
                <div className='flex flex-col justify-center gap-4'>
                    <p>Dine valgte fag vil forsvinne om du bytter undervisningsform</p>
                    <div className='flex justify-center gap-4'>
                        <Button onClick={() => selectSchool(switchSchool)}>Ja</Button>
                        <Button onClick={() => handleCloseModal()} mode='dark'>Nei</Button>
                    </div>
                </div>
            </Modal>

            <div className='mb-4 mt-4'>
                <p className='font-semibold text-lg'>Se priser i neste steg</p>
            </div>

            {showNext && <div className="w-full bg-white sticky bottom-0 mt-4 pt-2 pb-2">
                <Button onClick={() => navigate('/fag')} mode="dark">Neste</Button>
            </div>}
        </>
    )
}