import { Icon } from "../Icon/Icon";

export function ExamExample() {

    return (
        <div className="flex h-[62px] gap-1">
            <div className={`w-[35px] tablet-s:w-[40px] gap-1 bg-first-color rounded-lg shadow-md border-2 border-first-color-outline flex flex-col justify-center items-center p-2 cursor-pointer`}>
                <Icon className='w-5' type='written-exam' />
                <Icon className='w-5' type='written-exam' />
                <Icon className='w-5' type='oral-exam' />
            </div>
            <div className='border rounded-lg shadow-md flex bg-white'>
                <div className='flex justify-center text-left pl-1 py-1 pr-4 w-full'>
                    <p className='font-semibold text-base tablet-s:text-xl'>Norsk muntlig og skriftlig</p>
                </div>
            </div>
        </div>
    )
}