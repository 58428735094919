export interface CourseRequirements {
  requirement: boolean;
  courseCodes: string[];
  courseNames: string[];
}

const GetCourseRequirements: Record<string, CourseRequirements[]> = {
  "MAT1023": [
      {
        requirement: true,
        courseCodes: ["MAT1019", "MAT1021"],
        courseNames: ["Matematikk 1P", "Matematikk 1T"],
      }
    ],
    "MAT1151": [
      {
        requirement: true,
        courseCodes: ["ulike y-koder"],
        courseNames: ["Matematikk 1P-Y"]
      }
    ],
    "REA3058": [
      {
        requirement: true,
        courseCodes: ["REA3056", "REA3060", "REA3062", "REA3028"],
        courseNames: ["Matematikk R1", "Matematikk S1 + Matematikk S2"]
      }
    ],
    "REA3062": [
      {
        requirement: true,
        courseCodes: ["REA3060"],
        courseNames: ["Matematikk S1"]
      }
    ],
    "REA3046/REA3047": [
      {
        requirement: true,
        courseCodes: ["REA3045"],
        courseNames: ["Kjemi 1"]
      }
    ],
    "REA3039/REA3040": [
      {
        requirement: true,
        courseCodes: ["REA3038"],
        courseNames: ["Fysikk 1"]
      }
    ],
    "SAM3073": [
      {
        requirement: false,
        courseCodes: ["SAM3072"],
        courseNames: ["Psykologi 1"],
      }
    ],
    "REA3049/REA3050": [
      {
        requirement: false,
        courseCodes: ["REA3048"],
        courseNames: ["Informasjonsteknologi 1"],
      }
    ],
    "REA3043/REA3044": [
      {
        requirement: false,
        courseCodes: ["REA3042"],
        courseNames: ["Geofag 1"],
      }
    ],
    "SAM3058": [
      {
        requirement: false,
        courseCodes: ["SAM3057"],
        courseNames: ["Rettslære 1"],
      }
    ]
};

export default GetCourseRequirements