import { useState } from "react"
import { Icon } from "../Icon/Icon"
import Modal from "../Modal/Modal"
import { PageTitle } from "../PageTitle/PageTitle"
import { ExamExample } from "./ExamExample"

export function ExamInfo() {
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    function handleCloseModal() {
        setModalOpen(false)
    }

    function handleOpenModal() {
        setModalOpen(true)
    }

    return (
        <div>
            <div onClick={() => handleOpenModal()} className="mt-4 border-4 rounded-lg shadow-md border-second-color bg-pink-transparent flex justify-center p-2 hover:cursor-pointer">
                <div className="flex flex-col justify-around w-full">
                    <p className="font-semibold tablet-s:text-lg mobile-s:text-sm">Se etter disse ikonene i hvert fag for type eksamen:</p>
                    <div className="flex justify-center gap-4 align-baseline text-sm tablet-s:text-base">
                        <div className="flex items-center tablet-s:gap-2 mobile-s:gap-1">
                            <Icon className="w-4" type="written-exam" />
                            <p>Skriftlig</p>
                        </div>
                        <div className="flex items-center tablet-s:gap-2  mobile-s:gap-1">
                            <Icon className="w-4" type="oral-exam" />
                            <p>Muntlig</p>
                        </div>
                        <div className="flex items-center tablet-s:gap-2 mobile-s:gap-1">
                            <Icon className="w-4" type="oral-lab-exam" />
                            <p>Muntlig-praktisk</p>
                        </div>
                    </div>
                </div>
            </div>
            <Modal id="exam_info_popup" color="second-color" title='Type eksamen:' isOpen={modalOpen} onClose={() => handleCloseModal()}>
                <div className="flex gap-8">
                    <div className="flex flex-col">
                        <div className="flex gap-2 p-2 text-sm tablet-s:text-base">
                            <Icon type='written-exam' />
                            <p>Skriftlig</p>
                        </div>
                        <div className="flex gap-2 p-2 text-sm tablet-s:text-base">
                            <Icon type='oral-exam' />
                            <p>Muntlig</p>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center">
                        <ExamExample />
                    </div>
                </div>
                <div className="flex gap-2 p-2 text-sm tablet-s:text-base">
                    <Icon type='oral-lab-exam' />
                    <div className="flex flex-col text-left">
                        <p>Muntlig-praktisk</p>
                        <p>(den praktiske delen er gjerne en lab-øvelse)</p>
                    </div>
                </div>
                <p className="text-sm tablet-s:text-base">Fag du tar som privatist har en bestemt type eksamen.</p>
                <p className="text-sm tablet-s:text-base">Som du ser i bildet over har norsk 3 eksamener</p>
                <p className="text-sm tablet-s:text-base pt-4">Uavhengig av om du tar undervisning live, online eller på campus, må du fysisk møte opp til selve eksamen.</p>
            </Modal>
        </div>
    )
}