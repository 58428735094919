type Props = {
    title: string;
}

export function PageTitle({ title }: Props) {
    return (
        <div className="mt-1 mb-4 laptop-m:mt-4 laptop-m:mb-8">
            <h1 className="text-lg w-3/4 mx-auto tablet-s:w-full tablet-s:text-2xl font-bold">{title}</h1>
        </div>
    )
}