import { useEffect, useState } from "react";
import { selectedCourses } from "../../../../types"
import { getChosenSemVariants } from "../../../Helpers/CourseSearch/CourseSearchHelpers";
import FormatPrice from "../../../Helpers/Functions/FormatPrice";
import { checkIfEarlyAccessCourse } from "../../../Helpers/GetText/libraries/GetCourseCodes";

type Props = {
    course: selectedCourses
}

export function VerificationLine({ course }: Props) {
    const [semester, setSemester] = useState('');
    const [place, setPlace] = useState('');
    const [title, setTitle] = useState('');
    const [price, setPrice] = useState(0);

    useEffect(() => {
        setTitle(course.selectedCourse.courseTitle)
        setPrice(course.selectedPrice)
        const autumn = getChosenSemVariants('autumn');
        const spring = getChosenSemVariants('spring');
        let semSuffix = ' - halvår'
        if (course.selectedSemester === 'year') {
            semSuffix = ' - helår'
        }

        if (autumn.indexOf(course.selectedSemester) !== -1) {
            setSemester('Høst')
        } else if (spring.indexOf(course.selectedSemester) !== -1 && course.type !== 'online') {
            setSemester('Vår')
        } else if (course.selectedSemester === 'year' && course.earlyStart !== true) {
            setSemester('Helår - start høst')
        } else if (course.selectedSemester === 'yearspring' && course.type !== 'online') {
            setSemester('Helår - start vår')
        } else if (course.selectedSemester === 'yearspring' && course.type === 'online') {
            setSemester('Helår - start vår')
        } else if(course.selectedSemester === 'spring' && course.type === 'online') {
            setSemester('Vår')
        }
        
        if (course.type === 'online' && course.earlyStart === true) {
            setSemester('Tilgang i dag!')
        }

        if (course.type === 'online' && autumn.indexOf(course.selectedSemester) !== -1 && checkIfEarlyAccessCourse(course.courseCode)) {
            setSemester('Høst - tilgang i dag!')
        }

        if(course.type === 'online' && course.selectedSemester === 'year' && checkIfEarlyAccessCourse(course.courseCode)){
            setSemester('Helår - tilgang i dag!')
        }

        if (course.type === 'online') {
            setPlace('Online')
        } else if (course.type === 'live') {
            setPlace('Live')
        } else {
            setPlace('Campus')
        }
    }, [course])

    return (
        <div className="w-full flex justify-between p-2">
            <div className="w-full flex flex-col text-left">
                <p className="text-base tablet-s:text-lg font-semibold">{title.replace('Matematikk', 'Matte').replace('matematikk', 'matte')}</p>
                <p>{place} | {semester}</p>
            </div>
            {!course.packCourse && <p className="w-full text-right font-semibold">{FormatPrice(price)},-</p>}
        </div>
    )
}