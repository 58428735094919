import { useEffect, useState } from 'react';
import { Button } from '../Button/Button';
import { CourseCard } from '../CourseCard/CourseCard';
import { Icon } from '../Icon/Icon';
import Modal from '../Modal/Modal';
import { CourseSearch } from '../CourseSearch/CourseSearch';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourses } from '../../../Reducers/courses';
import {
  AppDispatch,
  AppState,
  ICourses,
  PackStructure,
  StructuredCourse,
  selectedCourses,
  IPackageCoverage,
} from '../../../../types';
import { ExamInfo } from './ExamInfo';
import { SemesterCourses } from './SemesterCourses';
import { StudyLoad } from './StudyLoad';
import { YearCourses } from './YearCourses';
import { Carousel } from '../Carousel/Carousel';
import FormatPrice from '../../../Helpers/Functions/FormatPrice';
import { SonansPackPopup } from './SonansPackPopup';
import { useNavigate } from 'react-router-dom';
import { EarlyStartModal } from '../ModalContents/EarlyStartModal';
import { distributeCourses } from '../../../Helpers/CourseDistributor/CourseDistributorHelper';
import { calculatePackageCoverage } from '../../../Helpers/PackageCoverage/PackageCoverage';
import { findPackCourseCodes } from '../../../Helpers/PackageCoursePicker/PackageCoursePicker';
// import { LateOrderModal } from '../ModalContents/LateOrderModal';
import {
  GetAvailableSemesters,
  GetCurrentSemesters,
  GetPreviousSemesters,
} from '../../../Helpers/Functions/GetAvailableSemesters';
import { setSemester } from '../../../Helpers/Functions/SetSemester';

interface Props {
  structuredCourses: StructuredCourse[];
  courses: ICourses[];
  coursePacks: PackStructure[];
}

export function SemesterCourseContainer({ structuredCourses, courses, coursePacks }: Props) {
  const [modalStates, setModalStates] = useState<boolean[]>([]);
  const [semesters, setSemesters] = useState<any[]>([]);
  const [selCourses, setSelCourses] = useState<selectedCourses[]>([]);
  const [newSelections, setNewSelections] = useState<selectedCourses[]>([]);
  const [selPacks, setSelPacks] = useState<PackStructure[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalBeforePrice, setTotalBeforePrice] = useState<number>(0);
  const [hasSonansPack, setHasSonansPack] = useState<boolean>(false);
  const [sonansPackModal, setSonansPackModal] = useState<boolean>(false);
  const [examFallModal, setExamFallModal] = useState<boolean>(false);
  const [yearModal, setYearModal] = useState<boolean>(false);
  const [hasYear, setHasYear] = useState<boolean>(false);
  const [willGetNormalPack, setWillGetNormalPack] = useState<IPackageCoverage | null>(null);
  const [schoolAlias, setSchoolAlias] = useState<string>('');
  const [isPrevSem, setIsPrevSem] = useState<boolean>(false);
  const [confirmLateOrder, setConfirmLateOrder] = useState<boolean>(false);
  const [loanPopup, setLoanPopup] = useState<boolean>(false);

  // TODO: Remove this?
  const [packCoverage, setPackCoverage] = useState<IPackageCoverage[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const courses = JSON.parse(localStorage.getItem('selectedCourses') || '[]');
    const semesters = JSON.parse(localStorage.getItem('semesters') || '');
    const packs = JSON.parse(localStorage.getItem('selectedPack') || '[]');
    const alias = localStorage.getItem('schoolAlias') || '';
    const prevSem = JSON.parse(localStorage.getItem('prevSem') || 'false');

    setSchoolAlias(alias);
    setSemesters(semesters);
    setSelCourses(courses);
    setNewSelections(courses);
    setSelPacks(packs);
    setIsPrevSem(prevSem);
    const schoolType = localStorage.getItem('schoolType');
    if (prevSem && schoolType === 'campus') {
      localStorage.setItem('schoolType', 'kombi');
    }
  }, []);

  useEffect(() => {
    let total = 0;
    let totalBefore = 0;
    selCourses.map((c) => {
      if (c.packCourse === false) {
        total = total + c.selectedPrice;
      }
      totalBefore = totalBefore + c.selectedPrice;
    });

    const packageCoverage = calculatePackageCoverage(selCourses, coursePacks, structuredCourses, schoolAlias);
    let normalPackDiscount = 0;
    let sonansPackDiscount = 0;

    // If you can have the normal pack, but dont, check the discount on this
    if (packageCoverage.length > 0) {
      normalPackDiscount = packageCoverage[0].savingsIfAdded;
      setWillGetNormalPack(packageCoverage[0]);
      localStorage.setItem('packCoverage', JSON.stringify(packageCoverage[0]));
      total += packageCoverage[0].priceAdjustment;
      if (selPacks.length > 0 && packageCoverage[0].package.alias !== selPacks[0].alias) {
        setSelectedPack(packageCoverage[0]);
      } else if (selPacks.length === 0) {
        setSelectedPack(packageCoverage[0]);
      }
    } else {
      if (selPacks.length !== 0) {
        removePacks();
      }
      setWillGetNormalPack(null);
      localStorage.setItem('packCoverage', '');
    }

    selPacks.map((p) => {
      total = total + p.price;
    });

    setTotalPrice(total);
    if (packageCoverage.length > 0) {
      setTotalBeforePrice(totalBefore);
    } else {
      setTotalBeforePrice(totalBefore);
    }

    const yearCourses = selCourses.filter((c) => c.earlyStart !== true && c.selectedSemester === 'yearspring');
    if (yearCourses.length > 0) {
      setHasYear(true);
    } else {
      setHasYear(false);
    }
  }, [selCourses]);

  useEffect(() => {
    //update price total and saving
    let total = 0;
    let totalBefore = 0;
    selCourses.forEach((c) => {
      if (c.packCourse === false) {
        total += c.selectedPrice;
      }
      totalBefore += c.selectedPrice;
    });

    if (willGetNormalPack) {
      total += willGetNormalPack.priceAddedCourses - willGetNormalPack.savingsIfAdded;
    }

    setTotalPrice(total);
    setTotalBeforePrice(totalBefore);
  }, [selCourses, selPacks, willGetNormalPack]);

  useEffect(() => {
    if (selPacks.length > 0 && selCourses.length > 0) {
      updatePackStatusCourses(selCourses, selPacks[0]);
    } else if (selPacks.length === 0 && selCourses.length > 0) {
      removePackStatusCourses(selCourses);
    }
  }, [selPacks]);

  useEffect(() => {
    setModalStates(Array(semesters.length).fill(false));
  }, [semesters]);

  function handleSonansPackModalClose() {
    setSonansPackModal(false);
  }

  function handleSonansPackModalOpen() {
    setSonansPackModal(true);
  }

  const handleOpenModal = (index: number) => {
    const updatedModalStates = [...modalStates];
    updatedModalStates[index] = true;
    setModalStates(updatedModalStates);
  };

  const handleCloseModal = (index: number) => {
    const updatedModalStates = [...modalStates];
    updatedModalStates[index] = false;
    setModalStates(updatedModalStates);
    if (selCourses !== newSelections) {
      setSelCourses(newSelections);
      localStorage.setItem('selectedCourses', JSON.stringify(newSelections));
    }
  };

  function handleOpenExamModal() {
    setExamFallModal(true);
  }

  function handleCloseExamModal() {
    setExamFallModal(false);
  }

  function handleOpenYearModal() {
    setYearModal(true);
  }

  function handleCloseYearModal() {
    setYearModal(false);
  }

  function handleOpenLateOrderConfirm() {
    setConfirmLateOrder(true);
  }

  function handleCloseLateOrderConfirm() {
    setConfirmLateOrder(false);
  }

  function setSelectedPack(pack: IPackageCoverage) {
    const selPack: PackStructure = {
      agreements: pack.package.agreements,
      alias: pack.package.alias,
      courses: pack.package.courses,
      lookup: pack.package.lookup,
      placement: pack.package.placement,
      price: pack.package.price,
      title: pack.package.title,
    };
    setSelPacks([selPack]);
    localStorage.setItem('selectedPack', JSON.stringify([selPack]));
  }

  function updatePackStatusCourses(courses: selectedCourses[], pack: PackStructure) {
    let packCourses: string[] = [];
    if (pack && pack.courses) {
      packCourses = findPackCourseCodes(pack.courses, structuredCourses);
    }

    let courseArr = [...courses];
    courseArr.forEach((c, i) => {
      if (packCourses.indexOf(c.courseCode) !== -1 || (pack && pack.alias === 'sonanspack')) {
        courseArr[i].packCourse = true;
        courseArr[i].pack = pack.alias;
        courseArr[i].packTitle = pack.title;
      } else {
        courseArr[i].packCourse = false;
        courseArr[i].pack = '';
        courseArr[i].packTitle = '';
      }
    });

    if (selCourses !== courseArr) {
      let sortedCourseArr = sortCourses(courseArr);
      setSelCourses(sortedCourseArr);

      setNewSelections(sortedCourseArr);
      localStorage.setItem('selectedCourses', JSON.stringify(sortedCourseArr));
    }
  }

  function removePackStatusCourses(courses: selectedCourses[]) {
    let courseArr = [...courses];
    courseArr.forEach((c, i) => {
      courseArr[i].packCourse = false;
      courseArr[i].pack = '';
      courseArr[i].packTitle = '';
    });

    if (selCourses !== courseArr) {
      let sortedCourseArr = sortCourses(courseArr);
      setSelCourses(sortedCourseArr);
      setNewSelections(sortedCourseArr);
      localStorage.setItem('selectedCourses', JSON.stringify(sortedCourseArr));
    }
  }

  function selectCourses(myCourses: selectedCourses[]) {
    // TODO: Place real agreement alias here!
    //const distributedCourses = distributeCourses(myCourses, schoolAlias);

    updatePackStatusCourses(myCourses, selPacks[0]);
  }

  function sortCourses(courses: selectedCourses[]) {
    const yearSemesters = ['year', 'yearspring'];
    const autumnSemesters = ['autumn', 'summer_school', 'introautumn', 'lab_practice_autum'];
    const springSemesters = ['spring', 'newyear_school', 'introspring', 'lab_practice_spring'];
    let courseArr = [...courses];
    let yearArr = courseArr.filter((c) => yearSemesters.indexOf(c.selectedSemester) !== -1);
    let autumnArr = courseArr.filter((c) => autumnSemesters.indexOf(c.selectedSemester) !== -1);
    let springArr = courseArr.filter((c) => springSemesters.indexOf(c.selectedSemester) !== -1);
    yearArr = yearArr.sort((a, b) => {
      if (a.course.courseTitle.toLowerCase() < b.course.courseTitle.toLowerCase()) return -1;
      if (a.course.courseTitle.toLowerCase() > b.course.courseTitle.toLowerCase()) return 1;
      return 0;
    });
    autumnArr = autumnArr.sort((a, b) => {
      if (a.course.courseTitle.toLowerCase() < b.course.courseTitle.toLowerCase()) return -1;
      if (a.course.courseTitle.toLowerCase() > b.course.courseTitle.toLowerCase()) return 1;
      return 0;
    });
    springArr = springArr.sort((a, b) => {
      if (a.course.courseTitle.toLowerCase() < b.course.courseTitle.toLowerCase()) return -1;
      if (a.course.courseTitle.toLowerCase() > b.course.courseTitle.toLowerCase()) return 1;
      return 0;
    });

    let yearPackArr = yearArr.filter((c) => c.packCourse === true);
    let yearNoPackArr = yearArr.filter((c) => c.packCourse !== true);
    let autumnPackArr = autumnArr.filter((c) => c.packCourse === true);
    let autumnNoPackArr = autumnArr.filter((c) => c.packCourse !== true);
    let springPackArr = springArr.filter((c) => c.packCourse === true);
    let springNoPackArr = springArr.filter((c) => c.packCourse !== true);

    const fullArr = [
      ...yearPackArr,
      ...yearNoPackArr,
      ...autumnPackArr,
      ...autumnNoPackArr,
      ...springPackArr,
      ...springNoPackArr,
    ];

    return fullArr;
  }

  function selectPack(pack: PackStructure) {
    setSelPacks([pack]);
    localStorage.setItem('selectedPack', JSON.stringify([pack]));
  }

  function removePacks() {
    setSelPacks([]);
    localStorage.setItem('selectedPack', '');
  }

  function chooseNewSelections(selections: selectedCourses[]) {
    let sortSelections = sortCourses(selections);
    setNewSelections(sortSelections);
  }

  function changeSemesters() {
    if (!isPrevSem) {
      setIsPrevSem(true);
      let semesters = GetPreviousSemesters();
      setSemester(semesters[0]);
    } else {
      setIsPrevSem(false);
      let semesters = GetCurrentSemesters();
      setSemester(semesters[0]);
    }
    const schoolType = localStorage.getItem('schoolType');
    if (schoolType === 'campus' && !isPrevSem) {
      localStorage.setItem('schoolType', 'kombi');
    }

    const semesters = JSON.parse(localStorage.getItem('semesters') || '');
    setSemesters(semesters);
    handleCloseLateOrderConfirm();
    clearCart();
  }

  function clearCart() {
    setSelCourses([]);
    setNewSelections([]);
    setSelPacks([]);
    localStorage.removeItem('selectedPack');
    localStorage.removeItem('packCoverage');
    localStorage.removeItem('selectedCourses');
  }

  function openConfirm() {
    handleCloseExamModal();
    handleOpenLateOrderConfirm();
  }

  function returnFromConfirm() {
    handleCloseLateOrderConfirm();
    handleOpenExamModal();
  }

  return (
    <>
      <Carousel
        allStructuredCourses={structuredCourses}
        coursePacks={coursePacks}
        courses={courses}
        selCourses={selCourses}
        selPacks={selPacks}
        willGetNormalPack={willGetNormalPack}
        updatePackCourses={(selections) => selectCourses(selections)}
        updatePack={(pack) => selectPack(pack)}
      />
      {/* <div onClick={() => handleOpenExamModal()} className="mt-4 border-4 rounded-lg border-third-color-outline shadow-md bg-third-color flex justify-center p-2 mobile-s:p-1 hover:cursor-pointer">
                <div className="flex justify-start tablet-s:justify-center gap-4 items-center w-full"><Icon className="w-8 ml-2" type="online" /><div className="flex flex-col tablet-s:flex-row"><p><span className="font-bold">Kampanje!</span> Start i dag med onlinefag</p><p> - tidlig tilgang kostnadsfritt.</p></div></div>
            </div> */}
      {/* <Modal id="campaign_popup" color="third-color" isOpen={examFallModal} onClose={() => handleCloseExamModal()} title="Kampanje - start i dag!">
                <EarlyStartModal />
            </Modal > */}
      {/* <Modal
        id='campaign_popup'
        isOpen={examFallModal}
        onClose={() => handleCloseExamModal()}
        title={!isPrevSem ? 'Privatisteksamen våren 2024?' : 'Ta opp fag til høsten!'}>
        <LateOrderModal changeSemester={() => openConfirm()} isPrevSem={isPrevSem} />
      </Modal>
      <Modal
        id='late_order_confirmation'
        isOpen={confirmLateOrder}
        onClose={() => handleCloseLateOrderConfirm()}
        title='Er du sikker på at du vil bytte skoleår?'>
        <div className='flex flex-col justify-center items-center gap-2'>
          <div className='flex flex-col'>
            <p>Dine valgte fag vil forsvinne om du bytter skoleår.</p>
          </div>
          <div className='flex flex-row gap-2'>
            <Button onClick={() => changeSemesters()} mode='primary'>
              Ja
            </Button>
            <Button onClick={() => returnFromConfirm()} mode='dark'>
              Nei
            </Button>
          </div>
        </div>
      </Modal> */}
      <div className='mt-5 bg-sonans-primary'>
        <div className='flex justify-around'>
          {semesters &&
            semesters.map((exam: any) => (
              <div key={exam.semesterAlias + '-semester-heading'} className='flex flex-col p-1'>
                <p className='text-base tablet-s:text-xl font-bold'>{exam.semesterTitle}</p>
                <p className='text-sm tablet-s:text-base'>{exam.examString}</p>
              </div>
            ))}
        </div>
        <div
          style={{ background: 'linear-gradient(#D9D9D9, #D9D9D9) no-repeat center/2px 100%, #fff' }}
          className='border-x-4 border-sonans-primary'>
          <YearCourses
            courses={selCourses}
            selectCourses={(selections) => selectCourses(selections)}
            removePacks={() => removePacks()}
          />
        </div>
        <div
          style={{ background: 'linear-gradient(#D9D9D9, #D9D9D9) no-repeat center/2px 100%, #fff' }}
          className='flex justify-between border-x-4 border-sonans-primary'>
          {semesters.map((exam: any, index: number) => (
            <div key={exam.semesterAlias + '-semester-content'} className='basis-1/2 flex flex-col'>
              <div className='h-full'>
                <SemesterCourses
                  courses={selCourses}
                  semester={exam.semesterAlias}
                  selectCourses={(selections) => selectCourses(selections)}
                  removePacks={() => removePacks()}
                />
              </div>
              <div className='p-4 flex flex-col'>
                <Button onClick={() => handleOpenModal(index)} mode='dark'>
                  Legg til fag
                </Button>
              </div>
              <Modal id='course_search_popup' isOpen={modalStates[index]} onClose={() => handleCloseModal(index)}>
                <CourseSearch
                  setSelections={(e) => chooseNewSelections(e)}
                  semesterAlias={exam.semesterAlias}
                  courseArr={structuredCourses}
                  courseSelections={selCourses}
                  closeModal={() => handleCloseModal(index)}
                  addCourses={(selections) => selectCourses(selections)}
                />
              </Modal>
            </div>
          ))}
        </div>
        <div className='flex justify-around'>
          {semesters.map((exam, i) => (
            <StudyLoad
              key={exam.semesterAlias + '-semester-load'}
              courses={selCourses}
              showInfoIcon={i + 1 === semesters.length ? true : false}
              semester={exam.semesterAlias}
            />
          ))}
        </div>
      </div>
      <div className='flex flex-wrap gap-2 justify-between w-full tablet-s:w-auto tablet-s:flex-nowrap'>
        {/* <div
          onClick={() => handleOpenExamModal()}
          className='mt-4 border-4 w-full md:w-[16rem] text-sm rounded-lg border-sonans-primary shadow-md bg-white py-2 flex justify-center hover:cursor-pointer'>
          {!isPrevSem && (
            <div className='flex justify-center tablet-s:justify-center gap-4 items-center w-full'>
              <p className='flex md:flex-col'>
                Meldt deg opp til eksamen <span className='font-bold'>våren 2024?</span>
              </p>
            </div>
          )}
          {isPrevSem && (
            <div className='flex justify-center tablet-s:justify-center gap-4 items-center w-full'>
              <p className='flex md:flex-col'>
                <span>Bestill fag for</span> <span className='font-bold'>skoleåret 2024 - 2025?</span>
              </p>
            </div>
          )}
        </div> */}
        <div className='grow'>
          <ExamInfo />
        </div>
        {hasYear && !isPrevSem && (
          <div
            onClick={() => handleOpenYearModal()}
            className='mt-2 tablet-s:mt-4 border-4 rounded-lg shadow-md border-second-color bg-pink-transparent flex justify-center p-2 mobile-s:p-1 hover:cursor-pointer grow'>
            <div className='flex flex-col justify-around w-full'>
              <p className='font-semibold tablet-s:text-lg mobile-s:text-sm'>Faget avsluttes høsten 2025</p>
              <div className='flex justify-center gap-2'>
                <Icon className='w-4' type='arrow-thin-right' />
                <p>Del 1</p>
              </div>
            </div>
          </div>
        )}
        <div className='mt-2 tablet-s:mt-4 flex gap-2 mx-auto tablet-s:mx-0'>
          <div className='tablet-s:w-16 items-center justify-center flex flex-row tablet-s:flex-col h-full border-4 p-1 border-first-color-outline bg-first-color'>
            <Icon className='w-8' type='campus' />
            <p>Campus</p>
          </div>

          <div className='tablet-s:w-16 items-center justify-center flex flex-row tablet-s:flex-col h-full border-4 p-1 border-second-color-outline bg-second-color'>
            <Icon className='w-8' type='live' />
            <p>Live</p>
          </div>

          <div className='tablet-s:w-16 items-center justify-center flex flex-row tablet-s:flex-col h-full border-4 p-1 border-third-color-outline bg-third-color'>
            <Icon className='w-8' type='online' />
            <p>Online</p>
          </div>
        </div>
      </div>

      <Modal
        id='year_info_popup'
        color='second-color'
        title='Helårsfag våren 2025 - høsten 2025'
        onClose={() => handleCloseYearModal()}
        isOpen={yearModal}>
        <div className='flex flex-col justify-center gap-4'>
          <p>Undervisningen starter på våren og varer i 2 semester. Du melder deg opp til eksamen høsten 2025.</p>
        </div>
      </Modal>

      {totalPrice > 0 && (
        <div className='flex flex-col justify-center mt-8'>
          {totalBeforePrice !== totalPrice && (
            <p className='text-sm line-through text-text-grey'>{FormatPrice(totalBeforePrice)},-</p>
          )}
          <p className='text-xl font-semibold'>Totalpris {FormatPrice(totalPrice)},-</p>
          {hasSonansPack && (
            <p className='cursor-pointer underline' onClick={() => handleSonansPackModalOpen()}>
              Du har fått Sonanspakken
            </p>
          )}
          {willGetNormalPack && (
            <p className='cursor-pointer underline' onClick={() => handleSonansPackModalOpen()}>
              Du får rabatt med {willGetNormalPack.packageName}
            </p>
          )}
          <Modal
            id='pack_popup'
            title={selPacks.length > 0 ? selPacks[0].title.replace(' online', '').replace(' live', '') : 'Sonanspakken'}
            isOpen={sonansPackModal}
            onClose={() => handleSonansPackModalClose()}>
            <SonansPackPopup
              pack={
                selPacks.length > 0 ? selPacks[0].title.replace(' online', '').replace(' live', '') : 'Sonanspakken'
              }
            />
          </Modal>
        </div>
      )}

      <div className='w-full bg-white sticky bottom-0 mt-4 pt-2 pb-2'>
        <button onClick={() => setLoanPopup(true)} className='underline mb-2 w-full'>
          Lån og stipend
        </button>
        <Modal isOpen={loanPopup} onClose={() => setLoanPopup(false)}>
          <div className='w-full flex flex-col gap-4 items-center'>
            <p className='text-xl font-semibold'>Du kan søke lån og stipend fra lånekassen.</p>
            <p>
              Du kan få inntil 151.690,- i basislån per undervisningsår. Inntil 40 % av basislånet kan gjøres om til
              stipend.
            </p>
            <p>Du kan få ekstralån til skolepenger på inntil kr 77.192,- per år.</p>
            <p>Du får støtte ut ifra studiebelastningen din.</p>
            <p>Her kan du beregne hvor mye støtte du kan få Lånekassens støttekalkulator.</p>
            <a
              className='text-xl font-bold rounded-md border border-black py-2 px-4 bg-sonans-primary hover:bg-black hover:text-white'
              target='_blank'
              href='https://lanekassen.no/nb-NO/stipend-og-lan/norge/universitet-og-hogskole/'>
              Lånekassen
            </a>
          </div>
        </Modal>
        <Button disabled={selCourses.length > 0 ? false : true} onClick={() => navigate('/paamelding')} mode='dark'>
          Neste
        </Button>
      </div>
    </>
  );
}
