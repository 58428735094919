import { useEffect, useState } from 'react';
import { PageTitle, Button, Icon } from '../../../UI';
import { IPackageCoverage, PackStructure, selectedCourses } from '../../../../types';
import { VerificationLine } from './VerificationLine';
import FormatPrice from '../../../Helpers/Functions/FormatPrice';
import { PackLine } from './PackLine';
import { SelectedCourses } from '../../../UI/components/CourseSearch/SelectedCourses';
import { beginCheckoutGTAG } from '../../../Helpers/GTAG/checkout';
import { useNavigate } from 'react-router-dom';
import guarantee from '../../../../assets/garanti.svg';

export function Verification() {
  const [allCourses, setAllCourses] = useState<selectedCourses[]>([]);
  const [courses, setCourses] = useState<selectedCourses[]>([]);
  const [packCourses, setPackCourses] = useState<selectedCourses[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalFull, setTotalFull] = useState<number>(0);
  const [sonansPack, setSonansPack] = useState<boolean>(false);
  const [showLK, setShowLK] = useState<boolean>(false);
  const [showDB, setShowDB] = useState<boolean>(false);
  const [showFG, setShowFG] = useState<boolean>(false);
  const [packs, setPacks] = useState<PackStructure[]>([]);
  const [packCoverage, setPackCoverage] = useState<IPackageCoverage | null>(null);

  const navigate = useNavigate();

  function showText(category: string) {
    if (category === 'lk') {
      setShowLK(true);
      setShowDB(false);
    } else if (category === 'db') {
      setShowLK(false);
      setShowDB(true);
    } else if (category === 'fg') {
      setShowLK(false);
      setShowDB(false);
      setShowFG(true);
    }
  }

  function hideText() {
    setShowDB(false);
    setShowLK(false);
    setShowFG(false);
  }

  useEffect(() => {
    let total = 0;
    let totalFull = 0;
    allCourses.forEach((c) => {
      if (c.packCourse === false) {
        total += c.selectedPrice;
      }
      totalFull += c.selectedPrice;
    });

    if (packCoverage && packCoverage.priceAddedCourses && packCoverage.savingsIfAdded) {
      total += packCoverage.priceAddedCourses - packCoverage.savingsIfAdded;
    }

    // const fullCourses = courses.filter((c) => c.packCourse === false && (!c.courseCode.includes('intro-' || 'lab-')));
    const fullCourses = courses.filter(
      (c) => c.packCourse === false && !c.courseCode.includes('intro-') && !c.courseCode.includes('lab-')
    );

    let packAlias = '';
    if (packs.length > 0) {
      packAlias = packs[0].alias;
    }

    if (fullCourses.length > 5 || packAlias === 'sonanspack') {
      setSonansPack(true);
    }
    setTotalPrice(total);
    setTotalFull(totalFull);
  }, [allCourses, packCoverage]);

  useEffect(() => {
    const courseList = JSON.parse(localStorage.getItem('selectedCourses') || '[]');
    const pack = JSON.parse(localStorage.getItem('selectedPack') || '[]');
    const packList = courseList.filter((c: selectedCourses) => c.packCourse === true && c.pack !== 'sonanspack');
    const courses = courseList.filter(
      (c: selectedCourses) => c.packCourse === false || (c.packCourse == true && c.pack === 'sonanspack')
    );
    const packCover = JSON.parse(localStorage.getItem('packCoverage') || '[]');
    setAllCourses(courseList);
    setPackCoverage(packCover);
    setPackCourses(packList);
    setCourses(courses);
    setPacks(pack);
    beginCheckoutGTAG(courses, packs, 'begin_checkout', totalPrice);
  }, []);

  return (
    <>
      <PageTitle title='Sikre deg din studieplass nå' />
      <div className='flex flex-col divide-y-2'>
        {packs.map((p) => p.alias !== 'sonanspack' && <PackLine key={`${p.alias}-pack`} pack={p} />)}
        {packCourses.map((c) => (
          <VerificationLine key={c.courseCode + '-verification-line-pc'} course={c} />
        ))}
        {packCourses.length > 0 && courses.length > 0 && (
          <div className=' bg-primary-color-light p-2'>
            <p className='text-left text-lg font-bold'>Øvrige fag:</p>
          </div>
        )}
        {courses.map((c) => (
          <VerificationLine key={c.courseCode + '-verification-line-c'} course={c} />
        ))}
        {sonansPack && (
          <div className=' bg-primary-color-light w-full flex justify-between p-2'>
            <p className='text-left text-lg font-bold'>Sonanspakken</p>
            <p className='w-full text-right font-semibold'>15% rabatt</p>
          </div>
        )}
      </div>
      <div className='w-full border-b-2 border-black border-dashed mt-8 mb-8'></div>
      {totalPrice !== totalFull && (
        <div className='w-full flex pl-2 pr-2'>
          <p className='w-full text-right text-xl font-semibold'>
            <span className='text-sm line-through text-text-grey'>{FormatPrice(totalFull)},-</span>
          </p>
        </div>
      )}
      <div className='w-full flex pl-2 pr-2'>
        <p className='text-xl font-semibold w-full text-left'>Totalt</p>
        <p className='w-full text-right text-xl font-semibold'>
          <span className='bg-third-color'>{FormatPrice(totalPrice)},-</span>
        </p>
      </div>
      <p className='text-left pl-2 pr-2'>
        *I tillegg til kursavgiften vil det påløpet en semesteravgift på 975,- per semester. Dette gjelder ikke ved kjøp
        av kun labkurs eller forkurs.
      </p>
      <div className='w-full mt-8 mb-4'></div>

      {!showDB && !showLK && !showFG && (
        <div className='flex w-full justify-between gap-4 text-sm'>
          <div
            onClick={() => showText('lk')}
            className='border-2 border-primary-color p-2 tablet-m:p-4 rounded-md w-full flex flex-col gap-4 items-center'>
            <Icon className='w-16 tablet-s:w-28' type='loan' />
            <p className='text-xs lg:text-base font-semibold'>Lånekassen</p>
            <p>Se hvor mye støtte du kan få.</p>
            <div className='hover:cursor-pointer mt-auto'>
              <Icon className='w-2 rotate-[270deg]' type='arrow-full-left' />
            </div>
          </div>
          <div
            onClick={() => showText('db')}
            className='border-2 border-primary-color p-2 tablet-m:p-4 rounded-md w-full flex flex-col gap-4 items-center'>
            <Icon className='w-12 tablet-s:w-20' type='downpayment' />
            <p className='text-xs lg:text-base font-semibold'>Delbetaling</p>
            <p>Se når du får faktura.</p>
            <div className='hover:cursor-pointer mt-auto'>
              <Icon className='w-2 rotate-[270deg]' type='arrow-full-left' />
            </div>
          </div>
          <div
            onClick={() => showText('fg')}
            className='border-2 border-primary-color p-2 tablet-m:p-4 rounded-md w-full flex flex-col gap-4 items-center overflow-hidden'>
            <img src={guarantee} className='w-12 tablet-s:w-24' alt='fornøydhetsgaranti' />

            <p className='text-xs lg:text-base font-semibold w-full break-words hyphens-auto mobile-m:break-normal'>
              Fornøydhets&shy;garanti
            </p>
            <p>Fornøyd eller faget på nytt!</p>
            <div className='hover:cursor-pointer mt-auto'>
              <Icon className='w-2 rotate-[270deg]' type='arrow-full-left' />
            </div>
          </div>
        </div>
      )}

      {showLK && (
        <div className='border-2 border-primary-color p-4 rounded-md w-full flex gap-4'>
          <div className='flex align-center'>
            <Icon className='w-28' type='loan' />
          </div>
          <div className='w-full flex flex-col gap-4 items-center'>
            <p className='text-xl font-semibold'>Du kan søke lån og stipend fra lånekassen.</p>
            <p>
              Du kan få inntil 151.690,- i basislån per undervisningsår. Inntil 40 % av basislånet kan gjøres om til
              stipend.
            </p>
            <p>Du kan få ekstralån til skolepenger på inntil kr 77.192,- per år.</p>
            <p>Du får støtte ut ifra studiebelastningen din.</p>
            <p>Her kan du beregne hvor mye støtte du kan få Lånekassens støttekalkulator.</p>
            <a
              className='text-xl font-bold rounded-md border border-black py-2 px-4 bg-sonans-primary hover:bg-black hover:text-white'
              target='_blank'
              href='https://lanekassen.no/nb-NO/stipend-og-lan/norge/universitet-og-hogskole/'>
              Lånekassen
            </a>
          </div>
          <div className='h-full flex flex-col justify-start'>
            <div onClick={() => hideText()} className='hover:cursor-pointer justify-self-end'>
              <Icon className='w-4' type='close-icon' />
            </div>
          </div>
        </div>
      )}
      {showDB && (
        <div className='border-2 border-primary-color p-4 rounded-md w-full flex gap-4'>
          <div className='flex align-center'>
            <Icon className='w-28' type='downpayment' />
          </div>
          <div className='w-full flex flex-col gap-4 items-center'>
            <p className='text-xl font-semibold'>Delbetaling</p>
            <p>14 dager etter påmelding vil du bli fakturert et depositum på 1.950,-</p>
            <p>Resten faktureres etter skolestart, når du skal ha fått støtte fra Lånekassen.</p>
          </div>
          <div className='h-full flex flex-col justify-start'>
            <div onClick={() => hideText()} className='hover:cursor-pointer justify-self-end'>
              <Icon className='w-4' type='close-icon' />
            </div>
          </div>
        </div>
      )}
      {showFG && (
        <div className='border-2 border-primary-color p-4 rounded-md w-full flex gap-4'>
          <div className='flex align-center'>
            <img src={guarantee} className='w-28' alt='fornøydhetsgaranti' />
          </div>
          <div className='w-full flex flex-col gap-4 items-center'>
            <p className='text-xl font-semibold'>Hva er fornøydhetsgaranti? </p>
            <p>Hos oss skal alle bli fornøyde, derfor får du selvfølgelig fornøydhetsgaranti på Sonans.</p>

            <p>Fornøyd eller faget på nytt! Rett og slett. </p>

            <p>
              Skulle du av en eller annen grunn ha noe å utsette på vårt undervisningsopplegg får du faget på nytt
              neste semester, gratis.
            </p>
          </div>
          <div className='h-full flex flex-col justify-start'>
            <div onClick={() => hideText()} className='hover:cursor-pointer justify-self-end'>
              <Icon className='w-4' type='close-icon' />
            </div>
          </div>
        </div>
      )}
      <div className='w-full bg-white sticky bottom-0 mt-4 pt-2 pb-2'>
        <Button onClick={() => navigate('/bestill')} mode='dark'>
          Neste
        </Button>
      </div>
    </>
  );
}
